// @ts-nocheck
import {connect} from "react-redux";
import React, {FC, useEffect} from 'react';
import {Box, Container} from "@mui/material";

import ProcessStateFormWasSubmitted from "./common/processStateFormWasSubmitted/processStateFormWasSubmitted";
import ComplimentFormWasSubmitted from "./common/complimentFormWasSubmitted/complimentFormWasSubmitted";
import ClaimsFormWasSubmitted from "./common/claimsFormWasSubmitted/claimsFormWasSubmitted";
import ProcessStateDetails from "./common/processStateDetails/processStateDetails";
import ComplaintFormSelector from "./ComplaintFormSelector/ComplaintFormSelector";
import FormWithoutReservation from './FormWithoutReservation';
import {APP_SCENES} from "../../services/app/default-state";
import {setAppScene} from "../../services/app/actions";
import FormWithReservation from './FormWithReservation';
import Loading from "../../components/loading/loading";
import ProcessStateForm from "./FormProcessState";
import APP_SETTINGS from "../../config/settings";
import FormCompliment from './FormCompliment';
import DevTools from "../DevTools/DevTools";

interface RouterSwitcherInterface {
    currentAppScene: string;
    setAppScene: (appScene: string) => any;
}

const router = (currentForm: string): string => {
    if (currentForm.includes('compliment')) return APP_SCENES.COMPLIMENT;
    if (currentForm.includes('processStatus')) return APP_SCENES.PROCESS_STATE;
    return APP_SCENES.COMPLAINT_SELECTOR;
};

const RouteSwitcher: FC<RouterSwitcherInterface> = ({currentAppScene, setAppScene}) => {

    const currentDrupalClaimsFormType: string = APP_SETTINGS.DRUPAL_CLAIMS_FORM_TYPE;

    useEffect(() => {
        setAppScene(router(currentDrupalClaimsFormType));
    }, [currentDrupalClaimsFormType, setAppScene])

    const sceneSwitcher = (appScene: string) => {
        switch (appScene) {
            case APP_SCENES.COMPLAINT_WITH_RESERVATION:
                return <FormWithReservation/>;
            case APP_SCENES.COMPLAINT_WITHOUT_RESERVATION:
                return <FormWithoutReservation/>;
            case APP_SCENES.COMPLIMENT:
                return <FormCompliment/>;
            case APP_SCENES.COMPLAINT_SELECTOR:
                return <ComplaintFormSelector/>;
            case APP_SCENES.CLAIMS_FORM_MESSAGE_WAS_SUBMITTED:
                return <ClaimsFormWasSubmitted/>;
            case APP_SCENES.COMPLIMENT_FORM_MESSAGE_WAS_SUBMITTED:
                return <ComplimentFormWasSubmitted/>;
            case APP_SCENES.PROCESS_STATE:
                return <ProcessStateForm/>;
            case APP_SCENES.PROCESS_STATE_MESSAGE_WAS_SUBMITTED:
                return <ProcessStateFormWasSubmitted/>;
            case APP_SCENES.PROCESS_STATE_DETAILS:
                return <ProcessStateDetails/>;
            default:
                // @ts-ignore
                return <Loading loading={true}/>;
        }
    }

    return (
        <Container>
            <DevTools/>
            <Box style={{marginTop: '30px'}}>
                {sceneSwitcher(currentAppScene)}
            </Box>
        </Container>
    );
};

export default connect((state: any) => ({currentAppScene: state.app.appScene}), {setAppScene})(RouteSwitcher);

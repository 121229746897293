export const DATA = {
    pnr: '',
    lastName: '',
    ticketNumber: '',
};

export const DATA_FILLED = {
    pnr: 'U7R88Y',
    lastName: 'Fernandes',
    ticketNumber: '',
};

export const DATA_VALIDATOR = {
    pnr: s => /^(([a-zA-Z0-9]{6})|([a-zA-Z0-9]{13}))$/g.test(s),
    lastName: s => /^.+$/g.test(s),
    ticketNumber: s => /^([0-9]{13})$/g.test(s),
};

export const isAllValid = (inputData, isAGroup=false) => {
    if (isAGroup) {
        return DATA_VALIDATOR.ticketNumber(inputData.ticketNumber);
    }

    return DATA_VALIDATOR.pnr(inputData.pnr)
        && DATA_VALIDATOR.lastName(inputData.lastName);
};
import React, {FC, useState} from 'react';
import {
    AppBar, Divider, Fab,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon, ListItemSecondaryAction,
    ListItemText, ListSubheader,
    SwipeableDrawer, Switch,
    Toolbar,
    Typography
} from "@mui/material";
import BugReportIcon from '@mui/icons-material/BugReport';
import DrupalRouteSwitcherSimulator from "./DrupalRouteSwitcherSimulator/DrupalRouteSwitcherSimulator";
import LanguageDropdown from "../../components/language-dropdown/language-dropdown";
import MenuIcon from '@mui/icons-material/Menu';
import CloudIcon from '@mui/icons-material/Cloud';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import LanguageIcon from '@mui/icons-material/Language';
import TuneIcon from '@mui/icons-material/Tune';
import BuildIcon from '@mui/icons-material/Build';
import APP_SETTINGS from "../../config/settings";

export interface DevToolsInterface {
    showTopBar?: boolean
}

const DevTools: FC<DevToolsInterface> = ({showTopBar = false}) => {

    const [open, setOpen] = useState(APP_SETTINGS.FIXED_TOOLBAR);

    if (APP_SETTINGS.ENVIRONMENT !== 'DEV') {
        return null;
    }

    const changeSettingsFixedToolbar = (fixed: boolean) => {
        APP_SETTINGS.SET_FIXED_TOOLBAR(fixed);
        window.location.reload();
    };

    const changeSettingsMode = (mode: string) => {
        APP_SETTINGS.SET_MODE(mode);
        window.location.reload();
    }

    const changeSettingsDebug = (debug: boolean) => {
        APP_SETTINGS.SET_DEBUG(debug);
        window.location.reload();
    }

    return (
        <>
            {showTopBar && (
                <AppBar position="fixed">
                    <Toolbar>
                        <IconButton onClick={() => setOpen(true)} edge="start" color="inherit" aria-label="menu">
                            <MenuIcon/>
                        </IconButton>
                        <Typography variant="h6">
                            DevTools
                        </Typography>
                        <div style={{position: 'absolute', right: '10px'}}>
                            <LanguageDropdown isOnDark={true}/>
                        </div>
                    </Toolbar>
                </AppBar>
            )}
            {!open && !showTopBar && (
                <Fab style={{position: 'fixed', bottom: '10px', left: '10px'}}
                     size={'small'}
                     variant={"extended"}
                     color="primary"
                     aria-label="open-dev-tools"
                     onClick={() => setOpen(true)}>
                    <BuildIcon/>
                    &nbsp;DevTools
                </Fab>
            )}
            <SwipeableDrawer
                style={{minWidth: '280px'}}
                anchor={'left'}
                open={open}
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}>
                <div style={{minWidth: '280px'}}>
                    <Divider/>
                    <List subheader={<ListSubheader><b>Toolbar</b></ListSubheader>}>
                        <ListItem>
                            <ListItemIcon>
                                <TuneIcon/>
                            </ListItemIcon>
                            <ListItemText primary={'Fixed'}/>
                            <ListItemSecondaryAction>
                                <Switch checked={APP_SETTINGS.FIXED_TOOLBAR}
                                        onChange={(e, checked) => changeSettingsFixedToolbar(checked)}/>
                            </ListItemSecondaryAction>
                        </ListItem>
                    </List>
                    <Divider/>
                    <List subheader={<ListSubheader><b>Language</b></ListSubheader>}>
                        <ListItem>
                            <ListItemIcon><LanguageIcon/></ListItemIcon>
                            <ListItemText primary={'Current'}/>
                            <ListItemSecondaryAction>
                                <LanguageDropdown isOnDark={false}/>
                            </ListItemSecondaryAction>
                        </ListItem>
                    </List>
                    <Divider/>
                    <List subheader={<ListSubheader><b>Routes</b></ListSubheader>}>
                        <DrupalRouteSwitcherSimulator/>
                    </List>
                    <Divider/>
                    <List subheader={<ListSubheader><b>Source</b></ListSubheader>}>
                        <ListItemButton
                            disabled={APP_SETTINGS.MODE === APP_SETTINGS.MODES.MOCK}
                            onClick={() => changeSettingsMode(APP_SETTINGS.MODES.MOCK)}>
                            <ListItemIcon><CloudOffIcon/></ListItemIcon>
                            <ListItemText primary={'MOCK'}/>
                        </ListItemButton>
                        <ListItemButton
                            disabled={APP_SETTINGS.MODE === APP_SETTINGS.MODES.API}
                            onClick={() => changeSettingsMode(APP_SETTINGS.MODES.API)}>
                            <ListItemIcon><CloudIcon/></ListItemIcon>
                            <ListItemText primary={'API'}/>
                        </ListItemButton>
                    </List>
                    <Divider/>
                    <List subheader={<ListSubheader><b>Mode</b></ListSubheader>}>
                        <ListItem>
                            <ListItemIcon>
                                <BugReportIcon/>
                            </ListItemIcon>
                            <ListItemText primary={'Debug'}/>
                            <ListItemSecondaryAction>
                                <Switch checked={APP_SETTINGS.DEBUG}
                                        onChange={(e, checked) => changeSettingsDebug(checked)}/>
                            </ListItemSecondaryAction>
                        </ListItem>
                    </List>
                </div>
            </SwipeableDrawer>
        </>
    );
};
export default DevTools;

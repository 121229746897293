import {useDispatch, useSelector} from "react-redux";
import {useSnackbar} from "notistack";
import {removeNotification} from "../../../services/app/actions";
import {useEffect} from "react";

const NotistackNotifier = () => {

    const dispatch = useDispatch();
    const notifications = useSelector(store => store.app.notifications);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        notifications.forEach(({key, message, config}) => {
            dispatch(removeNotification(key));
            enqueueSnackbar(message, config);
        });
    }, [notifications]);

    return null;
};

export default NotistackNotifier;

export const DATA = {
    additionalInformation: '',
    attachments: [],
};

export const DATA_VALIDATOR = {
    additionalInformation: s => true,
    attachments: attachments => Array.isArray(attachments),
};

export const IS_FORM_VALID = inputData => {
    return DATA_VALIDATOR.additionalInformation(inputData.additionalInformation)
        && DATA_VALIDATOR.attachments(inputData.attachments);
};
import React from "react";
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {Backdrop, CircularProgress} from "@mui/material";

const Loading = ({loading = true, addEndListener = () => null}) => {

    if (!loading) {
        return null;
    }

    return (
        <Backdrop addEndListener={addEndListener} transitionDuration={100} timeout={100} open={loading} style={{zIndex: 100}}>
            <CircularProgress color="inherit"/>
        </Backdrop>
    );
};

Loading.propTypes = {
    loading: PropTypes.bool,
    addEndListener: PropTypes.func,
};

export default connect(state => ({loading: state.app.loading}))(Loading);

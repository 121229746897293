import * as TYPES from "./types";
import OCCURRENCES_DEFAULT_STATE from "./default-state";

export default function (state = OCCURRENCES_DEFAULT_STATE, action) {
    switch (action.type) {
        case TYPES.SET_LOADING:
            return {...state, loading: action.loading};
        case TYPES.ADD_NOTIFICATION:
            return {...state, notifications: [...state.notifications, {...action.notification}]};
        case TYPES.REMOVE_NOTIFICATION:
            return {...state, notifications: [...state.notifications.filter(n => n.key !== action.key)]};
        case TYPES.SET_LANGUAGE:
            return {...state, language: action.language};
        case TYPES.SET_APP_SCENE:
            return {...state, appScene: action.appScene};
        case TYPES.SET_PROCESS_NUMBER_DETAILS:
            return {...state, processNumberDetails: action.processNumberDetails};
        case TYPES.SET_SUBJECT_TYPES_LIST:
            return {...state, subjectTypesList: [...action.subjectTypesList]};
        case TYPES.SET_SUBJECT_TYPES_LIST_FILTERED:
            return {...state, subjectTypesListFiltered: [...action.subjectTypesListFiltered]};
        default:
            return state;
    }
}

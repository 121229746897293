import * as TYPES from "./types";
import {FLIGHT_INFO_MOCK, SCENES} from './mock';
import client from 'axios';
import APP_SETTINGS from "../../config/settings";
import {addNotification, setAppScene, setLoading} from "../app/actions";
import {parseApiDateTime} from "../../config/tools";
import {t} from 'ttag';
import {APP_SCENES} from "../app/default-state";


export function setCurrentStep(step) {
    return dispatch => {
        return dispatch({type: TYPES.SET_CURRENT_STEP, currentStep: step});
    }
}

export function setScene(scene) {
    return dispatch => {
        return dispatch({type: TYPES.SET_SCENE, scene: scene});
    }
}

export function setFlightInfo(flightInfo) {
    return dispatch => {
        dispatch({type: TYPES.SET_FLIGHT_INFO, flightInfo});
        return Promise.resolve({flightInfo});
    }
}

export function setModifiedFlightInfo(modifiedFlightInfo) {
    return dispatch => {
        dispatch({type: TYPES.SET_MODIFIED_FLIGHT_INFO, modifiedFlightInfo});
        return Promise.resolve({modifiedFlightInfo});
    }
}

export function setModifiedFlightInfoPassengers(passengers) {
    return dispatch => {
        const nextStep = 1;
        dispatch({type: TYPES.SET_MODIFIED_FLIGHT_INFO_PASSENGERS, passengers});
        dispatch(setCurrentStep(nextStep));
        return Promise.resolve({currentStep: nextStep, passengers});
    }
}

export function setModifiedFlightInfoFlights(flights) {
    return dispatch => {
        const nextStep = 2;
        dispatch({type: TYPES.SET_MODIFIED_FLIGHT_INFO_FLIGHTS, flights});
        dispatch(setCurrentStep(nextStep));
        return Promise.resolve({currentStep: nextStep, flights});
    }
}

export function setModifiedFlightInfoAdditionalInformation(additionalInformation) {
    return dispatch => {
        dispatch({type: TYPES.SET_MODIFIED_FLIGHT_INFO_ADDITIONAL_INFORMATION, additionalInformation});
        return Promise.resolve({additionalInformation});
    }
}

export function setModifiedFlightInfoAttachments(attachments) {
    return dispatch => {
        dispatch({type: TYPES.SET_MODIFIED_FLIGHT_INFO_ATTACHMENTS, attachments});
        return Promise.resolve({attachments});
    }
}

export function setModifiedFlightInfoAdditionalInformationAndAttachments(additionalInformation, attachments) {
    return dispatch => {
        dispatch(setModifiedFlightInfoAdditionalInformation(additionalInformation));
        dispatch(setModifiedFlightInfoAttachments(attachments));
        return Promise.resolve({additionalInformation, attachments});
    }
}

export function retrieveFlightInfo(reservationCode, lastName, requestedPagination = {}) {
    return dispatch => {
        dispatch(setLoading(true));

        if (APP_SETTINGS.isInMockMode()) {
            const flightInfo = {...FLIGHT_INFO_MOCK, additionalInformation: '', attachments: []};
            dispatch({type: TYPES.SET_FLIGHT_INFO, flightInfo: {...flightInfo}});
            dispatch(setModifiedFlightInfo({...flightInfo}));

            if (flightInfo.isAGroup && reservationCode.length === 6) {
                dispatch(setLoading(false));
                return Promise.resolve({...flightInfo});
            }

            if (flightInfo.isAGroup && reservationCode.length === 13) {
                dispatch(reservationGroupFilterPassengersBy(reservationCode));
            }

            dispatch(setCurrentStep(0));
            dispatch(setScene(SCENES.STEPPER));
            dispatch(setLoading(false));
            return Promise.resolve({...flightInfo});
        }

        if (APP_SETTINGS.isInApiMode()) {
            //call /reservation CustomerClaims API endpoint.
            return client.get(`${APP_SETTINGS.API_ENDPOINT}/reservation/${reservationCode}`, {
                params: {
                    ...requestedPagination,
                    lastName
                }
            })
                .catch(e => {
                    console.warn(e);
                    dispatch({type: TYPES.SET_FLIGHT_INFO, flightInfo: null});
                    dispatch(setModifiedFlightInfo(null));

                    try {
                        const {response} = e;
                        const {status, data} = response;

                        if (status === 404) {
                            dispatch(addNotification(t`Reservation not found`, {variant: 'error'}));
                        } else {
                            const {error, description} = data;
                            dispatch(addNotification(`${error}: ${description}`, {variant: 'error'}));
                        }
                    } catch (caught) {
                        dispatch(addNotification(e.message, {variant: 'error'}));
                    }
                    return Promise.reject(e);
                })
                .then(result => {
                    const {data} = result;
                    const flightInfo = {
                        ...data,
                        passengers: data.passengers.map(p => ({...p, dateOfBirth: parseApiDateTime(p.dateOfBirth)})),
                        flights: data.flights.map(f => ({
                            ...f,
                            departure: parseApiDateTime(f.departure),
                            arrival: parseApiDateTime(f.arrival),
                            reasonId: f.claimSubCategory
                        })),
                        createdOn: parseApiDateTime(data.createdOn),
                        additionalInformation: '',
                        attachments: [],
                    };

                    dispatch(setFlightInfo({...flightInfo, lastName}));
                    dispatch(setModifiedFlightInfo({...flightInfo}));

                    if (flightInfo.isAGroup && reservationCode.length === 6) {
                        return Promise.resolve({...data});
                    }

                    if (flightInfo.isAGroup && reservationCode.length === 13) {
                        dispatch(reservationGroupFilterPassengersBy(reservationCode));
                    }

                    dispatch(setCurrentStep(0));
                    dispatch(setScene(SCENES.STEPPER));

                    return Promise.resolve({...data});


                })
                .finally(() => {
                    dispatch(setLoading(false));
                });
        }
    };
}

function setTicketNumberError(value) {
    return (dispatch, getState) => {
        const {customerClaims} = getState();
        const {modifiedFlightInfo} = customerClaims;
        return dispatch(setModifiedFlightInfo({...modifiedFlightInfo, ticketNumberError: value}));
    }
}

export function reservationGroupFilterPassengersBy(ticketNumber) {
    return (dispatch, getState) => {
        const {customerClaims} = getState();
        const {modifiedFlightInfo} = customerClaims;
        try {
            const {passengers, tickets} = modifiedFlightInfo;
            const ticket = tickets.find(t => t.ticketNumber === ticketNumber);
            const {passenger} = ticket;
            const {passengerId} = passenger;
            const newData = {
                ...modifiedFlightInfo,
                passengers: passengers.filter(p => p.passengerId === passengerId)
            };

            dispatch(
                setModifiedFlightInfo({...newData})
            );

            dispatch(setCurrentStep(0));
            dispatch(setScene(SCENES.STEPPER));
            return Promise.resolve({...newData});
        } catch (e) {
            dispatch(setTicketNumberError(true));
            dispatch(addNotification(t`Ticket not found.`, {variant: 'error'}))
            return Promise.reject(e.message);
        }
    };
}

export function submitStep4(additionalInformation, attachments) {
    return dispatch => {
        dispatch(setModifiedFlightInfoAdditionalInformationAndAttachments(additionalInformation, attachments));
        return dispatch(submitModifiedFlightInfo());
    }
}

export function resetCustomerClaimsForm() {
    return dispatch => {
        dispatch(setScene(SCENES.PNR_FORM));
        dispatch(setCurrentStep(0));
        dispatch(setFlightInfo(null));
        dispatch(setModifiedFlightInfo(null));
    }
}

export function submitModifiedFlightInfo() {
    return (dispatch, getState) => {
        dispatch(setLoading(true));

        if (APP_SETTINGS.isInMockMode()) {
            dispatch(setAppScene(APP_SCENES.CLAIMS_FORM_MESSAGE_WAS_SUBMITTED))
            dispatch(resetCustomerClaimsForm());
            dispatch(setLoading(false));
            /*dispatch(addNotification(t`Your contact is always important to us.
Within 48 hours, you will receive in your email the confirmation of the registration of your complaint/suggestion with more information. 
If you do not receive this email within the deadline, please check your unsolicited mailbox or send an email to customer.care@sata.pt.  
We hope to resolve your situation, meeting your expectations. Thank you.`, {variant: 'success'}));*/
            return Promise.resolve();
        }

        if (APP_SETTINGS.isInApiMode()) {
            //call /claims CustomerClaims API endpoint.
            const {customerClaims} = getState();
            return client.post(`${APP_SETTINGS.API_ENDPOINT}/claims`, {
                ...customerClaims.modifiedFlightInfo,
                lastName: customerClaims?.flightInfo?.lastName,
                additionalInfo: customerClaims.modifiedFlightInfo.additionalInformation,
                passengers: customerClaims?.modifiedFlightInfo?.passengers?.filter(p => !!p.selected).map(p => ({
                    ...p,
                    flyerID: p.frequentFlyer,
                    birthDate: p.dateOfBirth?.format(APP_SETTINGS.DATE_FORMAT)
                })) || [],
                flights: customerClaims?.modifiedFlightInfo?.flights?.filter(f => !!f.selected).map(f => ({
                    ...f,
                    reasonId: f.claimSubCategory
                })) || [],
                language: APP_SETTINGS.LANGUAGES_MAP[APP_SETTINGS.CURRENT_LANGUAGE]
            })
                .catch(e => {
                    console.warn(e);
                    try {
                        const {response} = e;
                        const {data} = response;
                        const {error, description} = data;
                        dispatch(addNotification(`${error}: ${description}`, {variant: 'error'}));
                    } catch (caught) {
                        dispatch(addNotification(e.message, {variant: 'error'}));
                    }

                    return Promise.reject(e);
                })
                .then(result => {
                    const {data} = result;
                    dispatch(setAppScene(APP_SCENES.CLAIMS_FORM_MESSAGE_WAS_SUBMITTED));
                    dispatch(resetCustomerClaimsForm());
                    /*dispatch(addNotification(t`Your contact is always important to us.
Within 48 hours, you will receive in your email the confirmation of the registration of your complaint/suggestion with more information.
If you do not receive this email within the deadline, please check your unsolicited mailbox or send an email to customer.care@sata.pt.
We hope to resolve your situation, meeting your expectations. Thank you.`, {variant: 'success'}));*/
                    return Promise.resolve(data);
                })
                .finally(() => {
                    dispatch(setLoading(false));
                });
        }
    };
}


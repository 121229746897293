import * as TYPES from "./types";
import DEFAULT_STATE from "./default-state";

export default function (state = DEFAULT_STATE, action) {
    switch (action.type) {
        case TYPES.SET_FLIGHT_INFO:
            return {...state, flightInfo: action.flightInfo};
        case TYPES.SET_CURRENT_STEP:
            return {...state, currentStep: action.currentStep};
        case TYPES.SET_SCENE:
            return {...state, scene: action.scene};
        case TYPES.SET_MODIFIED_FLIGHT_INFO:
            return {...state, modifiedFlightInfo: {...action.modifiedFlightInfo}};
        case TYPES.SET_MODIFIED_FLIGHT_INFO_PASSENGERS:
            return {...state, modifiedFlightInfo: {...state.modifiedFlightInfo, passengers: action.passengers}};
        case TYPES.SET_MODIFIED_FLIGHT_INFO_FLIGHTS:
            return {...state, modifiedFlightInfo: {...state.modifiedFlightInfo, flights: action.flights}};
        case TYPES.SET_MODIFIED_FLIGHT_INFO_ADDITIONAL_INFORMATION:
            return {...state, modifiedFlightInfo: {...state.modifiedFlightInfo, additionalInformation: action.additionalInformation}};
        case TYPES.SET_MODIFIED_FLIGHT_INFO_ATTACHMENTS:
            return {...state, modifiedFlightInfo: {...state.modifiedFlightInfo, attachments: action.attachments}};
        default:
            return state;
    }
}

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {SCENES} from "../../../../services/customer-compliment/mock";
import Stepper from "../steps/stepper/stepper";
import {t} from 'ttag';


const SceneSwitcher = ({scene = SCENES.PNR_FORM}) => {

    switch (scene) {
        case SCENES.STEPPER:
            return (
                <div className={'CustomerClaimsComplimentFormContainer'}>
                    <Stepper/>
                </div>
            );
        default:
            return t`Customer Claims Form - Compliment`;
    }
};

SceneSwitcher.propTypes = {
    scene: PropTypes.oneOf(Object.values(SCENES)),
};

export default connect(state => ({scene: state.customerComplaint.complaintScene}))(SceneSwitcher);

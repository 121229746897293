import * as TYPES from './types';
import DEFAULT_STATE from './default-state';

export default function (state = DEFAULT_STATE, action) {
    switch (action.type) {
        case TYPES.SET_PROCESS:
            return {...state, process: action.process};
        case TYPES.SET_PROCESS_NUMBER:
            return {...state, processNumber: action.processNumber};
        default:
            return state;
    }
}
import client from 'axios';
import APP_SETTINGS from "../../config/settings";
import * as TYPES from "./types";
import {addNotification, setAppScene, setLoading} from "../app/actions";
import {APP_SCENES} from "../app/default-state";


export function setComplaintCurrentStep(step) {
    return dispatch => {
        return dispatch({type: TYPES.SET_COMPLAINT_CURRENT_STEP, complaintCurrentStep: step});
    }
}

export function setComplaintScene(scene) {
    return dispatch => {
        return dispatch({type: TYPES.SET_COMPLAINT_SCENE, complaintScene: scene});
    }
}

export function setComplaintInfo(complaintInfo) {
    return dispatch => {
        dispatch({type: TYPES.SET_COMPLAINT_INFO, complaintInfo});
        return Promise.resolve({complaintInfo});
    }
}

export function setComplaintInfoCustomer(customer) {
    return dispatch => {
        dispatch({type: TYPES.SET_COMPLAINT_INFO_CUSTOMER, customer});
        return Promise.resolve({customer});
    }
}

export function setComplaintInfoClassification(classification) {
    return dispatch => {
        dispatch({type: TYPES.SET_COMPLAINT_INFO_CLASSIFICATION, classification});
        return Promise.resolve({classification});
    }
}

export function setComplaintInfoAdditionalInformation(additionalInformation) {
    return dispatch => {
        dispatch({type: TYPES.SET_COMPLAINT_INFO_ADDITIONAL_INFORMATION, additionalInformation});
        return Promise.resolve({additionalInformation});
    }
}

export function setComplaintInfoAttachments(attachments) {
    return dispatch => {
        dispatch({type: TYPES.SET_COMPLAINT_INFO_ATTACHMENTS, attachments});
        return Promise.resolve({attachments});
    }
}

export function customerComplaintStep1Submit(customer) {
    return dispatch => {
        const nextStep = 1;
        dispatch(setComplaintInfoCustomer(customer));
        dispatch(setComplaintCurrentStep(nextStep));
        return Promise.resolve({currentStep: nextStep, customer});
    }
}

export function customerComplaintStep2Submit(classification) {
    return dispatch => {
        const nextStep = 2;
        dispatch(setComplaintInfoClassification(classification));
        dispatch(setComplaintCurrentStep(nextStep));
        return Promise.resolve({currentStep: nextStep, classification});
    }
}

export function customerComplaintStep3Submit(additionalInformation, attachments) {
    return dispatch => {
        dispatch(setComplaintInfoAdditionalInformation(additionalInformation));
        dispatch(setComplaintInfoAttachments(attachments));
        return dispatch(submitCustomerComplaintData());
    }
}

export function resetComplaintForm() {
    return dispatch => {
        dispatch(setComplaintCurrentStep(0))
        dispatch(setComplaintInfo(null))
    }
}


export function submitCustomerComplaintData() {
    return (dispatch, getState) => {
        dispatch(setLoading(true));

        if (APP_SETTINGS.isInMockMode()) {
            dispatch(setAppScene(APP_SCENES.CLAIMS_FORM_MESSAGE_WAS_SUBMITTED))
            dispatch(resetComplaintForm());
            dispatch(setLoading(false));
            /*dispatch(addNotification(t`Your contact is always important to us.
Within 48 hours, you will receive in your email the confirmation of the registration of your complaint/suggestion with more information. 
If you do not receive this email within the deadline, please check your unsolicited mailbox or send an email to customer.care@sata.pt.  
We hope to resolve your situation, meeting your expectations. Thank you.`, {variant: 'success'}));*/
            return Promise.resolve();
        }

        if (APP_SETTINGS.isInApiMode()) {
            //call /claims-no-reservation CustomerClaims API endpoint.
            const {customerComplaint} = getState();
            const {complaintInfo} = customerComplaint;
            return client.post(`${APP_SETTINGS.API_ENDPOINT}/claims`, {
                passengers: [
                    {...complaintInfo.customer, flyerID: complaintInfo.customer.frequentFlyer}
                ],
                flights: [],
                claimReasonId: complaintInfo.classification,
                additionalInfo: complaintInfo.additionalInformation,
                attachments: complaintInfo.attachments,
                language: APP_SETTINGS.LANGUAGES_MAP[APP_SETTINGS.CURRENT_LANGUAGE]
            })
                .catch(e => {
                    console.warn(e);
                    try {
                        const {response} = e;
                        const {data} = response;
                        const {error, description} = data;
                        dispatch(addNotification(`${error}: ${description}`, {variant: 'error'}));
                    } catch (caught) {
                        dispatch(addNotification(e.message, {variant: 'error'}));
                    }

                    return Promise.reject(e);
                })
                .then(result => {
                    const {data} = result;
                    dispatch(setAppScene(APP_SCENES.CLAIMS_FORM_MESSAGE_WAS_SUBMITTED))
                    dispatch(resetComplaintForm());
                    /*dispatch(addNotification(t`Your contact is always important to us.
Within 48 hours, you will receive in your email the confirmation of the registration of your complaint/suggestion with more information.
If you do not receive this email within the deadline, please check your unsolicited mailbox or send an email to customer.care@sata.pt.
We hope to resolve your situation, meeting your expectations. Thank you.`, {variant: 'success'}));*/
                    return Promise.resolve(data);
                })
                .finally(() => {
                    dispatch(setLoading(false));
                });
        }
    };
}


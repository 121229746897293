import * as TYPES from "./types";
import APP_SETTINGS from "../../config/settings";
import HttpClient from "axios";
import SUBJECT_TYPES from "../../config/DATA/SUBJECT_TYPES";

export function setLoading(status) {
    return dispatch => {
        dispatch({type: TYPES.SET_LOADING, loading: status});
    }
}

export function addNotification(message = '', config = {}, key = null) {
    return dispatch => {
        key = key ? key : new Date().getTime() + Math.random();
        dispatch({type: TYPES.ADD_NOTIFICATION, notification: {key, message, config}})
    };
}

export function removeNotification(notificationKey) {
    return dispatch => {
        dispatch({type: TYPES.REMOVE_NOTIFICATION, key: notificationKey});
    };
}

export function setAppScene(appScene) {
    return dispatch => {
        dispatch({type: TYPES.SET_APP_SCENE, appScene});
    };
}

export function setProcessNumberDetails(response, processNumber) {
    return dispatch => {
        dispatch({type: TYPES.SET_PROCESS_NUMBER_DETAILS, processNumberDetails: {response, processNumber}});
    };
}

export function retrieveClaimsList(forClaims = 0) {
    return () => {
        if (APP_SETTINGS.MODE === APP_SETTINGS.MODES.MOCK) {
            return Promise.resolve({data: SUBJECT_TYPES});
        }

        if (APP_SETTINGS.MODE === APP_SETTINGS.MODES.API) {
            return HttpClient.get(`${APP_SETTINGS.API_ENDPOINT}/claim/types?forClaims=${forClaims}&language=${APP_SETTINGS.LANGUAGES_MAP[APP_SETTINGS.CURRENT_LANGUAGE]}`)
        }
    };
}

export function retrieveClaimsLists() {
    return dispatch => {
        dispatch(setLoading(true));
        const retrieveClaimsListPromise = dispatch(retrieveClaimsList(0))
            .then(({data}) => {
                const subjectTypesList = data;
                dispatch({type: TYPES.SET_SUBJECT_TYPES_LIST, subjectTypesList});
            })
            .catch(e => {
                dispatch(addNotification(e.message, {variant: 'error'}))
            })
            .finally(() => {
                return Promise.resolve();
            });

        const retrieveClaimsFilteredPromise = dispatch(retrieveClaimsList(1))
            .then(({data}) => {
                const subjectTypesListFiltered = data;
                dispatch({type: TYPES.SET_SUBJECT_TYPES_LIST_FILTERED, subjectTypesListFiltered});
            })
            .catch(e => {
                dispatch(addNotification(e.message, {variant: 'error'}));
            })
            .finally(() => {
                return Promise.resolve();
            });

        Promise.all([retrieveClaimsListPromise, retrieveClaimsFilteredPromise]).finally(() => {
            dispatch(setLoading(false));
        });
    };
}
import {t} from 'ttag'
import moment from "moment";
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {Checkbox, Grid2,CardHeader,Collapse,CardContent,Card, Typography} from "@mui/material";
import ClaimSubtypeAutocomplete from "../../../../../../../components/claims-autocomplete/claim-subtype-autocomplete/claim-subtype-autocomplete";
import ClaimTypeAutocomplete from "../../../../../../../components/claims-autocomplete/claim-type-autocomplete/claim-type-autocomplete";
import APP_SETTINGS from "../../../../../../../config/settings";
import {DATA_VALIDATOR} from "./flight-row-data";

const FlightMobileRow = ({flight = null, onFlightChange = () => null, onSelect = () => null, needAttention = false, selected = false, disabled = false, readOnly = false, callOnFlightChangeWhenType = false, claimSubjects = undefined, claimSubjectsLoading = false}) => {

    const [data, setData] = useState({
        ...flight
    });

    useEffect(() => {
        setData({...flight});
    }, [flight]);

    if (!flight || !data) {
        return null
    }

    const onChange = (fieldId, value) => {
        const modifiedData = {
            ...data,
            [fieldId]: value
        };

        setData({...modifiedData});
        if (callOnFlightChangeWhenType) {
            onFlightChange({...modifiedData})
        }
    };

    return (
        <Card key={data.flightId}>
            <CardHeader
                style={{cursor: 'pointer'}}
                onClick={() => onSelect(!selected)}
                avatar={(
                    <Checkbox
                        //onChange={(e, value) => onSelect(!!value)}
                        checked={selected}
                        value="secondary"
                        color="secondary"
                    />
                )}
                title={<>{data.origin.name} - {data.destination.name}</>}
                subheader={data.flightNumber}>
            </CardHeader>
            <Collapse in={selected} timeout="auto">
                <CardContent>
                    <Grid2 spacing={2} container>
                        <Grid2 size={{xs: 6}} >
                            <div style={{width: '100%', minWidth: '110px', textAlign: 'center', opacity: disabled ? '.6' : '1'}}>
                                <Typography variant={'caption'} color="textSecondary">
                                    {data.departure instanceof moment ? data.departure.format(APP_SETTINGS.DATE_TIME_FORMAT_NO_SEC) : ''}
                                </Typography>
                                <Typography variant="subtitle2" gutterBottom>
                                    {data.origin.iataCode} <small>{t`origin`}</small>
                                </Typography>
                            </div>
                        </Grid2>
                        <Grid2 size={{xs: 6}}>
                            <div style={{width: '100%', minWidth: '110px', textAlign: 'center', opacity: disabled ? '.6' : '1'}}>
                                <Typography variant={'caption'} color="textSecondary">
                                    {data.arrival instanceof moment ? data.arrival.format(APP_SETTINGS.DATE_TIME_FORMAT_NO_SEC) : ''}
                                </Typography>
                                <Typography variant="subtitle2" gutterBottom>
                                    {data.destination.iataCode} <small>{t`destination`}</small>
                                </Typography>
                            </div>
                        </Grid2>
                        <Grid2 size={{xs: 12}}>
                            <div style={{width: '100%', minWidth: '110px', textAlign: 'center', marginTop: '2%'}}>
                                <Typography variant="caption" gutterBottom>
                                    {t`Claim`}
                                </Typography>
                            </div>
                        </Grid2>
                        <Grid2 size={{xs: 12}}>
                            <Grid2 style={{width: '100%', minWidth: '230px'}} spacing={2} container>
                                <Grid2 size={{xs: 12, sm:6}}>
                                    <ClaimTypeAutocomplete
                                        label={t`Type`}
                                        subjectTypes={claimSubjects}
                                        loading={claimSubjectsLoading}
                                        value={data.claimCategory}
                                        onSelect={(claimCategory) => onChange('claimCategory', claimCategory)}
                                        error={needAttention && !DATA_VALIDATOR.claimCategory(data.claimCategory)}
                                        onBlur={() => onFlightChange({...data})}
                                        disabled={disabled}
                                        readOnly={readOnly}
                                        fullWidth
                                    />
                                </Grid2>
                                <Grid2 size={{xs:12, sm:6}} >
                                    <ClaimSubtypeAutocomplete
                                        label={t`Reason`}
                                        subjectTypes={claimSubjects}
                                        loading={claimSubjectsLoading}
                                        claimType={data.claimCategory}
                                        value={data.claimSubCategory}
                                        onSelect={(claimSubCategory) => onChange('claimSubCategory', claimSubCategory)}
                                        error={needAttention && !DATA_VALIDATOR.claimSubCategory(data.claimSubCategory)}
                                        onBlur={() => onFlightChange({...data})}
                                        disabled={disabled}
                                        readOnly={readOnly}
                                        fullWidth
                                    />
                                </Grid2>
                            </Grid2>
                        </Grid2>
                    </Grid2>
                </CardContent>
            </Collapse>
        </Card>
    );
};

FlightMobileRow.propTypes = {
    flight: PropTypes.object,
    onSelect: PropTypes.func,
    onFlightChange: PropTypes.func,
    selected: PropTypes.bool,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    needAttention: PropTypes.bool,
    callOnPaxChangeWhenType: PropTypes.bool,
    claimSubjects: PropTypes.array,
    claimSubjectsLoading: PropTypes.bool,
};
export default FlightMobileRow;

import FLIGHT_INFO_JSON from '../../config/DATA/WPJJE3.json';
import {parseApiDateTime} from "../../config/tools";

export const SCENES = {
    PNR_FORM: 'PNR_FORM',
    STEPPER: 'STEPPER'
}

export const FLIGHT_INFO_MOCK = {
    ...FLIGHT_INFO_JSON,
    passengers: FLIGHT_INFO_JSON.passengers.map(p => ({...p, dateOfBirth: parseApiDateTime(p.dateOfBirth)})),
    flights: FLIGHT_INFO_JSON.flights.map(p => ({
        ...p,
        departure: parseApiDateTime(p.departure),
        arrival: parseApiDateTime(p.arrival)
    })),
    createdOn: parseApiDateTime(FLIGHT_INFO_JSON.createdOn),
};

import {t} from "ttag";
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {Grid2, Typography} from "@mui/material";
import {setModifiedFlightInfoFlights} from "../../../../../services/customer-claims/actions";
import FlightsList from "./flights-form/flight-list/flight-list";

const Step3 = ({onSubmit = () => null, flights}) => {

    return (
        <Grid2 container spacing={2}>
            <Grid2 size={{xs: 12}} >
                <Typography variant={'caption'} gutterBottom style={{textAlign: 'justify'}}>
                    {t`Select the flights of the trip related to your complaint or suggestion.`}
                </Typography>
            </Grid2>
            <Grid2 size={{xs:12}} >
                <FlightsList onSubmit={onSubmit}/>
            </Grid2>
        </Grid2>
    );
};


Step3.propTypes = {
    onSubmit: PropTypes.func,
};

export default connect(state => ({flights: state.customerClaims.modifiedFlightInfo?.flights}), {onSubmit: setModifiedFlightInfoFlights})(Step3);

import React from 'react';
import { useTheme, useMediaQuery } from '@mui/material';

const withScreen = (PassedComponent) => (props) => {
    const theme = useTheme();
    const width = Object.keys(theme.breakpoints.values).reduce((output, key) => {
        const matches = useMediaQuery(theme.breakpoints.only(key));
        return !output && matches ? key : output;
    }, null);

    const screen = {
        width,
        breakpoints: { XS: 'XS', SM: 'SM', MD: 'MD', LG: 'LG', XL: 'XL' },
        breakpointsList: ['XS', 'SM', 'MD', 'LG', 'XL'],
        from: (bp) => screen.breakpointsList.indexOf(width.toUpperCase()) >= screen.breakpointsList.indexOf(bp.toUpperCase()),
        upTo: (bp) => screen.breakpointsList.indexOf(width.toUpperCase()) <= screen.breakpointsList.indexOf(bp.toUpperCase()),
        match: (bpList) => bpList.findIndex((b) => b.toUpperCase() === width.toUpperCase()) !== -1,
    };

    return <PassedComponent {...props} screen={screen} />;
};

export default withScreen;
import * as TYPES from "./types";
import client from 'axios';
import APP_SETTINGS from "../../config/settings";
import {addNotification, setAppScene, setLoading} from "../app/actions";
import {APP_SCENES} from "../app/default-state";


export function setComplimentCurrentStep(step) {
    return dispatch => {
        return dispatch({type: TYPES.SET_COMPLIMENT_CURRENT_STEP, complimentCurrentStep: step});
    }
}

export function setComplimentScene(scene) {
    return dispatch => {
        return dispatch({type: TYPES.SET_COMPLIMENT_SCENE, complimentScene: scene});
    }
}

export function setComplimentInfo(complimentInfo) {
    return dispatch => {
        dispatch({type: TYPES.SET_COMPLIMENT_INFO, complimentInfo});
        return Promise.resolve({complimentInfo});
    }
}

export function setComplimentInfoCustomer(customer) {
    return dispatch => {
        dispatch({type: TYPES.SET_COMPLIMENT_INFO_CUSTOMER, customer});
        return Promise.resolve({customer});
    }
}

export function setComplimentInfoClassification(classification) {
    return dispatch => {
        dispatch({type: TYPES.SET_COMPLIMENT_INFO_CLASSIFICATION, classification});
        return Promise.resolve({classification});
    }
}

export function setComplimentInfoAdditionalInformation(additionalInformation) {
    return dispatch => {
        dispatch({type: TYPES.SET_COMPLIMENT_INFO_ADDITIONAL_INFORMATION, additionalInformation});
        return Promise.resolve({additionalInformation});
    }
}

export function setComplimentInfoAttachments(attachments) {
    return dispatch => {
        dispatch({type: TYPES.SET_COMPLIMENT_INFO_ATTACHMENTS, attachments});
        return Promise.resolve({attachments});
    }
}

export function customerComplimentStep1Submit(customer) {
    return dispatch => {
        const nextStep = 1;
        dispatch(setComplimentInfoCustomer(customer));
        dispatch(setComplimentCurrentStep(nextStep));
        return Promise.resolve({currentStep: nextStep, customer});
    }
}

/*export function customerComplimentStep2Submit(classification) {
    return dispatch => {
        const nextStep = 2;
        dispatch(setComplimentInfoClassification(classification));
        dispatch(setComplimentCurrentStep(nextStep));
        return Promise.resolve({currentStep: nextStep, classification});
    }
}*/

export function customerComplimentStep2Submit(additionalInformation, attachments) {
    return dispatch => {
        dispatch(setComplimentInfoAdditionalInformation(additionalInformation));
        dispatch(setComplimentInfoAttachments(attachments));
        return dispatch(submitCustomerComplimentData());
    }
}

export function resetComplimentForm() {
    return dispatch => {
        dispatch(setComplimentCurrentStep(0));
        dispatch(setComplimentInfo(null))
    }
}

export function submitCustomerComplimentData() {
    return (dispatch, getState) => {
        dispatch(setLoading(true));

        if (APP_SETTINGS.isInMockMode()) {
            dispatch(resetComplimentForm());
            dispatch(setAppScene(APP_SCENES.COMPLIMENT_FORM_MESSAGE_WAS_SUBMITTED))
            dispatch(setLoading(false));
            /*dispatch(addNotification(t`Your contact is always important to us.
Within 48 hours, you will receive in your email the confirmation of the registration of your compliment/suggestion with more information.
If you do not receive this email within the deadline, please check your unsolicited mailbox or send an email to customer.care@sata.pt.
We hope to resolve your situation, meeting your expectations. Thank you.`, {variant: 'success'}));*/
            return Promise.resolve();
        }

        if (APP_SETTINGS.isInApiMode()) {
            //call /claims CustomerClaims API endpoint.
            const {customerCompliment} = getState();
            const {complimentInfo} = customerCompliment;
            return client.post(`${APP_SETTINGS.API_ENDPOINT}/compliments`, {
                passenger: {...complimentInfo.customer, flyerID: complimentInfo.customer.frequentFlyer},
                additionalInfo: complimentInfo.additionalInformation,
                attachments: [...complimentInfo.attachments],
                flights: [],
                language: APP_SETTINGS.LANGUAGES_MAP[APP_SETTINGS.CURRENT_LANGUAGE]
            })
                .catch(e => {
                    console.warn(e);
                    try {
                        const {response} = e;
                        const {data} = response;
                        const {error, description} = data;
                        dispatch(addNotification(`${error}: ${description}`, {variant: 'error'}));
                    } catch (caught) {
                        dispatch(addNotification(e.message, {variant: 'error'}));
                    }

                    return Promise.reject(e);
                })
                .then(result => {
                    const {data} = result;
                    dispatch(setAppScene(APP_SCENES.COMPLIMENT_FORM_MESSAGE_WAS_SUBMITTED));
                    dispatch(resetComplimentForm());
                    /*dispatch(addNotification(t`Your contact is always important to us.
Within 48 hours, you will receive in your email the confirmation of the registration of your compliment/suggestion with more information. 
If you do not receive this email within the deadline, please check your unsolicited mailbox or send an email to customer.care@sata.pt.  
We hope to resolve your situation, meeting your expectations. Thank you.`, {variant: 'success'}));*/
                    return Promise.resolve(data);
                })
                .finally(() => {
                    dispatch(setLoading(false));
                });
        }
    };
}


import {t} from 'ttag';
import React from 'react';
import {Grid2, Typography} from "@mui/material";
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {customerComplaintStep1Submit} from "../../../../../services/customer-complaint/actions";
import CustomerForm from "../../../common/customer-form/customer-form";

const Step1 = ({customer = undefined, onSubmit = () => null}) => {

    return (
        <Grid2 container spacing={2}>
            <Grid2 size={{xs: 12}}>
                <Typography variant={'caption'} gutterBottom style={{textAlign: 'justify'}}>
                    {t`Enter your details.`}
                </Typography>
            </Grid2>
            <Grid2 size={{xs:12}}>
                <CustomerForm customer={customer} onSubmit={onSubmit}/>
            </Grid2>
        </Grid2>
    );
};

Step1.propTypes = {
    onSubmit: PropTypes.func,
    customer: PropTypes.object,
};
export default connect(state => ({customer: state.customerComplaint?.complaintInfo?.customer}), {onSubmit: customerComplaintStep1Submit})(Step1);

// import {useLocale, addLocale, setDefaultLang} from 'ttag';
// import APP_SETTINGS from "./config/settings";
//
// setDefaultLang(APP_SETTINGS.DEFAULT_LANGUAGE);
// const addLocales = () => {
//     const locales = Object.values(APP_SETTINGS.LANGUAGES);
//     locales.forEach(locale => {
//         const translation = require(`../i18n/${locale}.po.json`);
//         addLocale(locale, translation);
//     })
//     useLocale(APP_SETTINGS.CURRENT_LANGUAGE);
// };
//
//
// addLocales();
// useLocale(APP_SETTINGS.CURRENT_LANGUAGE);

import {setDefaultLang, useLocale as setLocale} from 'ttag';
import APP_SETTINGS from "./config/settings";



export const changeLanguage = (lang) => {
    setLocale(lang);
}

export const i18nInit = () => {
    setDefaultLang(APP_SETTINGS.CURRENT_LANGUAGE);
};

(() => {
    i18nInit();
    changeLanguage(APP_SETTINGS.CURRENT_LANGUAGE);
})();


import {thunk} from 'redux-thunk';
// import { applyMiddleware, compose } from 'redux';

import createRootReducer from './reducers';
import {configureStore} from "@reduxjs/toolkit";
import api from "./api";


const initialState = {};

const enhancers = [

];

if (process.env.NODE_ENV === 'development') {
    const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

    if (typeof devToolsExtension === 'function') {
        enhancers.push(devToolsExtension());
    }
}

const store = configureStore({
    reducer: createRootReducer(),
    initialState: initialState,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk).concat(api.middleware),
    devTools: process.env.NODE_ENV === 'development',
});

export default store;

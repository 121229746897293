import {t} from 'ttag';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {Button, Grid2, TextField, Select, MenuItem, FormControl, InputLabel} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import PhoneNumberInput from "../../../../components/phone-number-input/phone-number-input";
import {DATA, DATA_VALIDATOR, IS_CUSTOMER_VALID} from "./customer-form-data";
import styles from './customer-form.module.scss';

const CustomerForm = ({customer = {...DATA}, onSubmit = () => null}) => {

    const [needAttention, setNeedAttention] = useState(false);
    const [data, setData] = useState({
        ...customer
    });

    const onChange = (fieldId, value) => {
        setData({
            ...data,
            [fieldId]: value
        });
    };

    const localSubmitHandler = () => {
        if (IS_CUSTOMER_VALID(data)) {
            onSubmit({...data, name: data.name.trim()});
        } else {
            setNeedAttention(true);
        }
    };

    return (
        <Grid2 spacing={3} container>
            <Grid2 size={{xs: 12, sm: 4}}>
                <FormControl
                    classes={{root: 'customerClaimsMuiFormControlSelect'}}
                    error={needAttention && !DATA_VALIDATOR.title(data.title)}
                    fullWidth
                    required>
                    <InputLabel>{t`Title`}</InputLabel>
                    <Select
                        value={data.title}
                        label={t`Title`}
                        error={needAttention && !DATA_VALIDATOR.title(data.title)}
                        onChange={(e) => onChange('title', e.target.value)}>
                        <MenuItem value={'mr'}>{t`Mr`}</MenuItem>
                        <MenuItem value={'mrs'}>{t`Mrs`}</MenuItem>
                    </Select>
                </FormControl>
            </Grid2>
            <Grid2 size={{xs: 12, sm: 4}}>
                <TextField
                    InputLabelProps={{shrink: true}}
                    variant="outlined"
                    label={t`Name`}
                    value={data.name}
                    error={needAttention && !DATA_VALIDATOR.name(data.name)}
                    helperText={needAttention && !DATA_VALIDATOR.name(data.name) ? t`You need to provide your first and last names.` : ''}
                    onChange={e => onChange('name', e.target.value)}
                    required
                    fullWidth
                />
            </Grid2>
            <Grid2 size={{xs: 12, sm: 4}}>
                <PhoneNumberInput
                    label={t`Phone`}
                    helperText={needAttention && !DATA_VALIDATOR.contact(data.contact) ? t`Please check field data` : t`Ex. +351926542554`}
                    error={needAttention && !DATA_VALIDATOR.contact(data.contact)}
                    onChange={(phoneNumber, country) => onChange('contact', phoneNumber)}
                    value={data.contact}
                    defaultCountry={'pt'}
                />
            </Grid2>
            <Grid2 size={{xs: 12, sm: 6}}>
                <TextField
                    InputLabelProps={{shrink: true}}
                    variant="outlined"
                    label={t`Email`}
                    value={data.email}
                    error={needAttention && !DATA_VALIDATOR.email(data.email)}
                    helperText={needAttention && !DATA_VALIDATOR.email(data.email) ? t`Please check field data` : ''}
                    onChange={e => onChange('email', e.target.value)}
                    required
                    fullWidth
                />
            </Grid2>
            <Grid2 size={{xs: 12, sm: 6}}>
                <TextField
                    variant="outlined"
                    InputLabelProps={{shrink: true, style: {width: '135%'}}}
                    label={t`Frequent Flyer Number`}
                    value={data.frequentFlyer}
                    error={needAttention && !DATA_VALIDATOR.frequentFlyer(data.frequentFlyer)}
                    helperText={needAttention && !DATA_VALIDATOR.frequentFlyer(data.frequentFlyer) ? t`Please check field data` : ''}
                    onChange={e => onChange('frequentFlyer', e.target.value)}
                    fullWidth
                />
            </Grid2>
            <Grid2 size={{xs: 12}}>
                <Button
                    className={styles.submitBtn}
                    variant="contained"
                    color="secondary"
                    onClick={localSubmitHandler}
                    endIcon={<NavigateNextIcon/>}>
                    {t`Next`}
                </Button>
            </Grid2>
        </Grid2>
    );
};

CustomerForm.propTypes = {
    onSubmit: PropTypes.func,
    customer: PropTypes.object,
};

export default CustomerForm;
export const subjectTypes = [
    {
        "subjectType": "CE Antecipação de horário",
        "subjectTypeDetails": [
            {"reasonId": "1", "reason": "Com informação do agente emissor"},
            {"reasonId": "2", "reason": "Falta de informação de agente emissor"}
        ]
    },
    {
        "subjectType": "CE Assistência a passageiros",
        "subjectTypeDetails": [
            {"reasonId": "3", "reason": "Menor não acompanhado"}
        ]
    },
    {
        "subjectType": "CE Assistência a passageiros de mobilidade reduzida",
        "subjectTypeDetails": [
            {"reasonId": "4", "reason": "Allergies"},
            {"reasonId": "5", "reason": "BLND + DEAF"},
            {"reasonId": "6", "reason": "BLND - Vision Impaired"},
            {"reasonId": "7", "reason": "Communicable disesase"},
            {"reasonId": "8", "reason": "DEAF - Hearing Impaired"},
            {"reasonId": "9", "reason": "Mentally impaired"},
            {"reasonId": "10", "reason": "Other Assistive device"},
            {"reasonId": "11", "reason": "Other disability"},
            {"reasonId": "12", "reason": "OXYG"},
            {"reasonId": "13", "reason": "Paraplegic"},
            {"reasonId": "14", "reason": "Quadriplegic"},
            {"reasonId": "15", "reason": "STCR"},
            {"reasonId": "16", "reason": "WCHC"},
            {"reasonId": "17", "reason": "WCHR"},
            {"reasonId": "18", "reason": "WCHS"}
        ]
    },
    {
        "subjectType": "CE Atendimento",
        "subjectTypeDetails": [
            {"reasonId": "19", "reason": "Aeroporto"},
            {"reasonId": "20", "reason": "Azores Express"},
            {"reasonId": "21", "reason": "Bordo"},
            {"reasonId": "22", "reason": "Contact Center"},
            {"reasonId": "23", "reason": "Handling"},
            {"reasonId": "24", "reason": "Loja"},
            {"reasonId": "25", "reason": "SATA Express"}
        ]
    },
    {
        "subjectType": "CE Atrasos",
        "subjectTypeDetails": [
            {"reasonId": "26", "reason": "Atraso de voo - Qualidade de serviço"},
            {"reasonId": "27", "reason": "Ausência de assistência"},
            {"reasonId": "28", "reason": "Demora na assistência"},
            {"reasonId": "29", "reason": "Falta de informações de agente emissor"},
            {"reasonId": "30", "reason": "Falta de informações no aeroporto"},
            {"reasonId": "31", "reason": "Pedido de indemnização"},
            {"reasonId": "32", "reason": "Pedido de reembolso de despesas (hotel/alimentação/transporte)"},
            {"reasonId": "33", "reason": "Perda de ligação com outro voo"}
        ]
    },
    {
        "subjectType": "CE Cancelamento",
        "subjectTypeDetails": [
            {"reasonId": "34", "reason": "Ausência de assistência"},
            {"reasonId": "35", "reason": "Cancelamento de voo - Qualidade de serviço"},
            {"reasonId": "36", "reason": "Demora na assistência"},
            {"reasonId": "37", "reason": "Falta de informações de agente emissor"},
            {"reasonId": "38", "reason": "Falta de informações no aeroporto"},
            {"reasonId": "39", "reason": "Pedido de indemnização"},
            {"reasonId": "40", "reason": "Pedido de reembolso de despesas"},
            {"reasonId": "41", "reason": "Perda de ligação com outro voo"}
        ]
    },
    {
        "subjectType": "CE Refeições a bordo",
        "subjectTypeDetails": [
            {"reasonId": "42", "reason": "Aeroporto"},
            {"reasonId": "43", "reason": "Bordo"}
        ]
    },
    {
        "subjectType": "CE Check-in",
        "subjectTypeDetails": [
            {"reasonId": "44", "reason": "Aeroporto"},
            {"reasonId": "45", "reason": "Downgrade"},
            {"reasonId": "46", "reason": "Mobile check-in"},
            {"reasonId": "47", "reason": "Telefónico"},
            {"reasonId": "48", "reason": "Web check-in"}
        ]
    },
    {
        "subjectType": "CE Condições a bordo",
        "subjectTypeDetails": [
            {"reasonId": "49", "reason": "Equipamentos avariados"},
            {"reasonId": "50", "reason": "Serviços"}
        ]
    },
    {
        "subjectType": "CE Condições nos aeroportos",
        "subjectTypeDetails": [
            {"reasonId": "51", "reason": "Demora na entrega de bagagem"},
            {"reasonId": "52", "reason": "Equipamentos avariados"},
            {"reasonId": "53", "reason": "Serviços"}
        ]
    },
    {
        "subjectType": "CE Elogios e sugestões",
        "subjectTypeDetails": [
            {"reasonId": "54", "reason": "Elogios e sugestões"}
        ]
    },
    {
        "subjectType": "CE Excesso de bagagem",
        "subjectTypeDetails": [
            {"reasonId": "55", "reason": "SPEQ"},
            {"reasonId": "56", "reason": "XBAG"}
        ]
    },
    {
        "subjectType": "CE Estado do processo",
        "subjectTypeDetails": [
            {"reasonId": "57", "reason": "Estado do processo"}
        ]
    },
    {
        "subjectType": "CE Outros assuntos",
        "subjectTypeDetails": [
            {"reasonId": "58", "reason": "Frequência de voos"},
            {"reasonId": "59", "reason": "Rotas disponíveis"},
            {"reasonId": "60", "reason": "Tarifas disponíveis"},
            {"reasonId": "61", "reason": "Questões de segurança"}
        ]
    },
    {
        "subjectType": "CE Reserva de lugar",
        "subjectTypeDetails": [
            {"reasonId": "62", "reason": "Bordo"},
            {"reasonId": "63", "reason": "Check-in"},
            {"reasonId": "64", "reason": "Reserva"}
        ]
    },
    {
        "subjectType": "CE Bagagem",
        "subjectTypeDetails": [
            {"reasonId": "65", "reason": "Artigos danificados a bordo"},
            {"reasonId": "66", "reason": "Artigos esquecidos pelo passageiro"},
            {"reasonId": "67", "reason": "Bagagem danificada"},
            {"reasonId": "68", "reason": "Bagagem de cabine"},
            {"reasonId": "69", "reason": "Demora na entrega de bagagem"},
            {"reasonId": "70", "reason": "Perda definitiva"},
            {"reasonId": "71", "reason": "Perda temporária"},
            {"reasonId": "72", "reason": "Pilferage"}
        ]
    },
    {
        "subjectType": "CE Recusa de embarque",
        "subjectTypeDetails": [
            {"reasonId": "73", "reason": "Ausência de assistência"},
            {"reasonId": "74", "reason": "Check-in"},
            {"reasonId": "75", "reason": "Demora na assistência"},
            {"reasonId": "76", "reason": "Embarque"},
            {"reasonId": "77", "reason": "No record"},
            {"reasonId": "78", "reason": "Overbooking"},
            {"reasonId": "79", "reason": "Pedido de indemnização"}
        ]
    },
    {
        "subjectType": "CE Reservas",
        "subjectTypeDetails": [
            {"reasonId": "80", "reason": "Data limite de pagamento"},
            {"reasonId": "81", "reason": "Deutsche Bahn"},
            {"reasonId": "82", "reason": "Erro na aplicação da tarifa"},
            {"reasonId": "83", "reason": "Erro na confirmação do valor"},
            {"reasonId": "84", "reason": "Erro na confirmação de voos"},
            {"reasonId": "85", "reason": "Informação de reembolso"},
            {"reasonId": "86", "reason": "Informação de tarifas"},
            {"reasonId": "87", "reason": "Informação de taxas"},
            {"reasonId": "88", "reason": "Informações de voos"},
            {"reasonId": "89", "reason": "Lista de espera"},
            {"reasonId": "90", "reason": "Pedidos especiais"},
            {"reasonId": "91", "reason": "Penalidades"},
            {"reasonId": "92", "reason": "Reemissão"},
            {"reasonId": "93", "reason": "Pagamento"},
            {"reasonId": "94", "reason": "Reservas SATA online"},
            {"reasonId": "95", "reason": "Aumento de tarifa"},
            {"reasonId": "96", "reason": "Aumento de taxas"},
            {"reasonId": "97", "reason": "Cobrança em duplicado"},
            {"reasonId": "98", "reason": "Cobrança incorreta de valor"},
            {"reasonId": "99", "reason": "Forma de pagamento"}
    ]}
];

export default subjectTypes;

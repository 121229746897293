import {t} from "ttag";
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {Grid2, Button} from "@mui/material";
import React, {useEffect, useState} from 'react';
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ClaimSubtypeAutocomplete from "../../../../../../components/claims-autocomplete/claim-subtype-autocomplete/claim-subtype-autocomplete";
import ClaimTypeAutocomplete from "../../../../../../components/claims-autocomplete/claim-type-autocomplete/claim-type-autocomplete";
import styles from "../../step-3/additional-info-and-attachments-form/additional-info-and-attachments-form.module.scss";
import {IS_FORM_VALID} from "./claims-form-data";

const ClaimsForm = ({onSubmit = () => null, claimSubtype = null, claimSubjects = []}) => {

    const [data, setData] = useState({claimType: null, claimSubtype});
    const [needAttention, setNeedAttention] = useState(false);

    useEffect(() => {
        if (claimSubjects.length && claimSubtype) {
            const claimType = claimSubjects.find(s => s.subjectTypeDetails.some(d => d.reasonId === claimSubtype));
            setData({claimType: claimType?.subjectType, claimSubtype});
        }
    }, [claimSubjects, claimSubtype]);

    const localDataHandler = (fieldId, value) => {
        const newData = {
            ...data,
            [fieldId]: value
        };

        setData(newData);
    };

    const localSubmitHandler = () => {
        if (IS_FORM_VALID(data)) {
            onSubmit(data?.claimSubtype);
        } else {
            setNeedAttention(true);
        }
    }

    return (
        <Grid2 spacing={1} container>
            <Grid2 size={{xs: 12, sm: 6}}>
                <ClaimTypeAutocomplete
                    label={t`Type`}
                    subjectTypes={claimSubjects}
                    value={data.claimType}
                    onSelect={v => localDataHandler('claimType', v)}
                    error={needAttention && !data.claimType}
                    fullWidth/>
            </Grid2>
            <Grid2 size={{xs: 12, sm: 6}}>
                <ClaimSubtypeAutocomplete
                    label={t`Reason`}
                    subjectTypes={claimSubjects}
                    claimType={data.claimType}
                    value={data.claimSubtype}
                    error={needAttention && !data.claimSubtype}
                    onSelect={v => localDataHandler('claimSubtype', v)}
                    fullWidth/>
            </Grid2>
            <Grid2 size={{xs: 12}} align={'right'}>
                <Button
                    className={styles.submitBtn}
                    variant="contained"
                    color="secondary"
                    onClick={localSubmitHandler}
                    endIcon={<NavigateNextIcon/>}>
                    {t`Next`}
                </Button>
            </Grid2>
        </Grid2>
    );
};

ClaimsForm.propTypes = {
    onSubmit: PropTypes.func,
    claimSubtype: PropTypes.any,
    claimSubjects: PropTypes.array,
};

export default connect(state => ({claimSubjects: state.app.subjectTypesListFiltered, claimSubtype: state.customerComplaint?.complaintInfo?.classification}))(ClaimsForm);

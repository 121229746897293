import React from 'react';
import * as serviceWorker from './serviceWorker';
import {Provider} from 'react-redux';
import store from './services/store';
import {createTheme, ThemeProvider} from '@mui/material';
import ScopedCssBaseline from "@mui/material/ScopedCssBaseline";
import App from "./App";
import themeData from './theme';
import styles from './index.module.scss';
import './config/resources/fonts/fonts.module.scss';
import {addLocale, useLocale} from "ttag";
import {LANGUAGES} from "./config/i18n-settings";
import {createRoot} from "react-dom/client";
import "./i18n";
import APP_SETTINGS from "./config/settings";

const theme = createTheme(themeData);

Promise.all([
    import(`./i18n/${LANGUAGES.EN}.po.json`),
    import(`./i18n/${LANGUAGES.FR}.po.json`),
    import(`./i18n/${LANGUAGES.PT}.po.json`)
]).then(([translationEN, translationFR, translationPT]) => {
    addLocale(LANGUAGES.EN, translationEN.default);
    addLocale(LANGUAGES.FR, translationFR.default);
    addLocale(LANGUAGES.PT, translationPT.default);

    useLocale(APP_SETTINGS.CURRENT_LANGUAGE);

    const container = document.getElementById('root-claims-form');
    const root = createRoot(container);

    root.render(
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <ScopedCssBaseline classes={{ root: styles.body }}>
                    <App />
                </ScopedCssBaseline>
            </ThemeProvider>
        </Provider>
    );
}).catch(error => {
    console.error("Error loading translations:", error);
});




// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React, {useState} from 'react';
import PropTypes from 'prop-types';
import 'react-phone-input-2/lib/style.css';
import './library-styles.scss';
import appStyles from '../../app.module.scss';
import styles from './phone-number-input.module.scss';
import {MuiTelInput} from "mui-tel-input";

const PhoneNumberInput = ({
                              label = '',
                              value = '+351',
                              disabled = false,
                              readOnly = false,
                              placeholder = '',
                              required = false,
                              error = false,
                              preferredCountries = ['pt', 'it', 'es', 'de', 'fr'],
                              defaultCountry = 'PT',
                              searchPlaceholder = '...',
                              onChange = () => null,
                              onBlur = () => null,
                              helperText = ''
                          }) => {

    const inputClasses = [styles.input, appStyles.inputExcluded];
    const buttonClasses = [styles.btnClass];
    const labelClasses = [styles.label];
    const [inputFocused, setInputFocused] = useState(false);
    if (!value && !defaultCountry) buttonClasses.push(styles.noValue);
    if (inputFocused) labelClasses.push(styles.labelFocused);
    if (disabled || readOnly) {
        inputClasses.push(styles.disabled);
        buttonClasses.push(styles.disabled);
    }
    if (error) {
        labelClasses.push(styles.labelError);
        inputClasses.push(styles.inputError);
        buttonClasses.push(styles.btnError);
    }

    const localOnBlurHandler = (e) => {
        setInputFocused(false);
        onBlur(e);
    };

    return (
        <MuiTelInput
            onChange={onChange}
            onFocus={() => setInputFocused(true)}
            onBlur={localOnBlurHandler}
            value={value.startsWith('+') || value === '' ? value !== '' ? value : '+351' : '+' + value}
            defaultCountry={defaultCountry.toLowerCase()}
            preferredCountries={preferredCountries}
            required={required}
            label={label}
            placeholder={placeholder + (required ? ' *' : '')}
            error={error}
            helperText={helperText}
            fullWidth
            disabled={disabled || readOnly}
        />

    );
};

PhoneNumberInput.propTypes = {
    value: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    error: PropTypes.bool,
    helperText: PropTypes.string,
    defaultCountry: PropTypes.string,
    searchPlaceholder: PropTypes.string,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    preferredCountries: PropTypes.array,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
};

export default PhoneNumberInput;

import {t} from 'ttag';
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {Grid2} from "@mui/material";
import ArrowButton from "../../../components/arrow-button/arrow-button";
import {APP_SCENES} from "../../../services/app/default-state";
import {setAppScene} from "../../../services/app/actions";
import styles from './ComplaintFormSelector.module.scss';

const ComplaintFormSelector = ({currentAppScene = APP_SCENES.EMPTY, setAppScene =  () => null}) => {
    return (
        <Grid2 spacing={5} container>
            <Grid2 size={{xs:12, sm:6, md:6}} className={styles.btnContainer}>
                <ArrowButton
                    className={styles.btn}
                    variant={'contained'}
                    onClick={() => setAppScene(APP_SCENES.COMPLAINT_WITH_RESERVATION)}
                    selected={currentAppScene === APP_SCENES.COMPLAINT_WITH_RESERVATION}>
                    {t`Complaint about a reservation`}
                </ArrowButton>
            </Grid2>
            <Grid2 size={{xs:12, sm:6, md:6}} className={styles.btnContainer}>
                <ArrowButton
                    className={styles.btn}
                    variant={'contained'}
                    onClick={() => setAppScene(APP_SCENES.COMPLAINT_WITHOUT_RESERVATION)}
                    selected={currentAppScene === APP_SCENES.COMPLAINT_WITHOUT_RESERVATION}>
                    {t`I don't have a reservation`}
                </ArrowButton>
            </Grid2>
            <Grid2 size={{xs:12}}>
                <br />
            </Grid2>
        </Grid2>
    );
};

ComplaintFormSelector.propTypes = {
    currentAppScene: PropTypes.string,
    setAppScene: PropTypes.func,
};

export default connect((state) => ({currentAppScene: state.app.appScene}), {setAppScene})(ComplaintFormSelector);

import {FLIGHT_INFO_MOCK, SCENES} from "./mock";
import APP_SETTINGS from "../../config/settings";

const DEFAULT_STATE = {
    flightInfo: APP_SETTINGS.DEBUG ? {...FLIGHT_INFO_MOCK} : null,
    modifiedFlightInfo: null,
    currentStep: 0,
    scene: SCENES.PNR_FORM
};

export default DEFAULT_STATE;
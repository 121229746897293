import moment from "moment";
import APP_SETTINGS from "./settings";

export const parseApiDateTime = apiDateTime => {
    if (!apiDateTime) return null;
    if (typeof apiDateTime === 'object') {
        const {date: dateWithMs} = apiDateTime;
        const dateWithoutMs = dateWithMs.split('.')[0];
        return moment.utc(dateWithoutMs).local().clone();
    }

    return moment.utc(apiDateTime).local().clone();
};

export const loadFile = async (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsBinaryString(file);
        reader.onload = () => resolve({
            content: btoa(reader.result),
            name: file.name,
            type: file.type,
            size: file.size
        });
        reader.onerror = error => reject(error);
    });
};

export const byteToMB = (bytes) => (bytes / 1000000).toFixed(1);

export const localToUtcStr = localTimeMomentInstance => localTimeMomentInstance.clone().utc().format(APP_SETTINGS.DATE_TIME_FORMAT);
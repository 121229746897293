import React from 'react';
import {Grid2, Typography} from "@mui/material";
import AdditionalInfoAndAttachmentsForm from "./additional-info-and-attachments-form/additional-info-and-attachments-form";
import {t} from "ttag";

const Step3 = () => {

    return (
        <Grid2 container spacing={2}>
            <Grid2 size={{xs: 12}}>
                <Typography variant={'caption'} gutterBottom style={{textAlign: 'justify'}}>
                    {t`All the information is important and can help us to improve. Use this field to provide detailed information about your experience. `}
                </Typography>
            </Grid2>
            <Grid2 size={{xs: 12}}>
                <AdditionalInfoAndAttachmentsForm/>
            </Grid2>
        </Grid2>
    );
};


export default Step3;

import {t} from "ttag";
import React from 'react';
import PropTypes from 'prop-types';
import {Autocomplete, CircularProgress, ListItem, TextField} from "@mui/material";
import SUBJECT_TYPES from "../../../config/DATA/SUBJECT_TYPES.js";

const ClaimTypeAutocomplete = ({subjectTypes = SUBJECT_TYPES, placeHolder = t`Start typing...`, label = t`Claim Type Select`, value = null, onSelect = () => null, required = false, error = false, disabled = false, readOnly = false, onBlur = () => null, fullWidth = false, loading = false}) => {

    return (
        <Autocomplete
            value={value}
            autoHighlight
            options={subjectTypes}
            loading={loading}
            onBlur={onBlur}
            onChange={(e, o) => onSelect(o ? o.subjectType : '')}
            isOptionEqualToValue={(o, selectedOpt) => o.subjectType === selectedOpt.subjectType}
            getOptionLabel={o => (typeof o === 'object') ? o.subjectType : o}
            disabled={disabled}
            readOnly={readOnly}
            fullWidth={fullWidth}
            renderOption={(props, option) => (
                <ListItem {...props}>
                    {option.subjectType}
                </ListItem>
            )}
            renderInput={params => (
                <TextField
                    {...params}
                    variant="outlined"
                    error={error}
                    required={required}
                    disabled={disabled}
                    autoFocus={false}
                    placeholder={placeHolder}
                    label={label}
                    fullWidth={fullWidth}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading ? <CircularProgress color='inherit' size={15} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    //readOnly: readOnly
                    // startAdornment: (
                    //     <InputAdornment position="start">
                    //         <PinDropIcon/>
                    //     </InputAdornment>
                    // ),
                    }}
                />
            )}/>
    );
};

ClaimTypeAutocomplete.propTypes = {
    subjectTypes: PropTypes.array,
    value: PropTypes.string,
    label: PropTypes.string,
    placeHolder: PropTypes.string,
    onSelect: PropTypes.func,
    onBlur: PropTypes.func,
    required: PropTypes.bool,
    error: PropTypes.bool,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    fullWidth: PropTypes.bool,
    loading: PropTypes.bool,
};


export default ClaimTypeAutocomplete;

import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {TextField, Grid2, DialogActions, DialogContentText, DialogContent, DialogTitle, Dialog} from "@mui/material";
import {t} from "ttag";
import styles from './additional-info-and-attachments-form.module.scss';
import Button from "@mui/material/Button";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {DATA_VALIDATOR, IS_FORM_VALID} from "./additional-info-and-attachments-form-data";
import {connect} from "react-redux";
import AttachmentsField from "../../../../../../components/AttachmentsField/AttachmentsField";
import {submitStep4} from "../../../../../../services/customer-claims/actions";


const AdditionalInfoAndAttachmentsForm = ({
                                              onSubmit = () => null,
                                              additionalInformation = '',
                                              attachments = [],
                                              maxCharacters = 2000
                                          }) => {

    const [isSubmitted, setIsSubmitted] = useState(false);
    const [needAttention, setNeedAttention] = useState(false);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({
        additionalInformation,
        attachments
    });

    const onLocalChange = (fieldId, value) => {
        setData({
            ...data,
            [fieldId]: value
        })
    };

    const localSubmitHandler = () => {
        if (IS_FORM_VALID(data)) {
            onSubmit({
                ...data,
            });
        } else {
            setNeedAttention(true);
        }
    };

    return (
        <Grid2 container>
            <Grid2 size={{xs: 12}}>
                <TextField
                    //label={t`Additional Information`}
                    variant="outlined"
                    value={data.additionalInformation ? data.additionalInformation : ''}
                    onChange={(e) => onLocalChange('additionalInformation', e.target.value.substring(0, maxCharacters))}
                    rows={4}
                    multiline
                    fullWidth
                    error={needAttention && !DATA_VALIDATOR.additionalInformation(data.additionalInformation)}
                    helperText={needAttention && !DATA_VALIDATOR.additionalInformation(data.additionalInformation)
                        ? t`Please check your field data`
                        : t`Characters left:` + ' ' + (maxCharacters - data.additionalInformation.length)}
                />
            </Grid2>
            <Grid2 size={{xs: 12, md: 9}}>
                <AttachmentsField
                    loadingHandlerFn={setLoading}
                    attachments={data.attachments}
                    onChange={(attachments) => onLocalChange('attachments', attachments)}/>
            </Grid2>
            <Grid2 size={{xs: 12}} align={'right'}>
                <Button
                    disabled={loading || isSubmitted}
                    className={styles.submitBtn}
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                        setIsSubmitted(true);
                        localSubmitHandler();
                    }}
                    //size={'small'}
                    endIcon={<NavigateNextIcon/>}>
                    {t`Submit`}
                </Button>
            </Grid2>
        </Grid2>
    )
};

AdditionalInfoAndAttachmentsForm.propTypes = {
    additionalInformation: PropTypes.string,
    attachments: PropTypes.array,
    onSubmit: PropTypes.func,
    maxCharacters: PropTypes.number,
};

export default connect(state => ({
    additionalInformation: state.customerClaims.modifiedFlightInfo?.additionalInformation,
    attachments: state.customerClaims.modifiedFlightInfo?.attachments
}), {
    onSubmit: ({additionalInformation, attachments}) => submitStep4(additionalInformation, attachments)
})(AdditionalInfoAndAttachmentsForm);

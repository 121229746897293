import {t} from "ttag";
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {Grid2, Typography} from "@mui/material";
import {setModifiedFlightInfoPassengers} from "../../../../../services/customer-claims/actions";
import PassengersList from "./passengers-form/passengers-list/passengers-list";

const Step2 = ({onSubmit = () => null}) => {

    return (
        <Grid2 container spacing={2}>
            <Grid2 size={{xs:12}} >
                <Typography variant={'caption'} gutterBottom style={{textAlign: 'justify'}}>
                    {t`Select the passengers who were affected by the situation.`}
                </Typography>
            </Grid2>
            <Grid2 size={{xs:12}} >
                <PassengersList onSubmit={onSubmit}/>
            </Grid2>
        </Grid2>
    );
};

Step2.propTypes = {
    onSubmit: PropTypes.func,
};

export default connect(null, {onSubmit: setModifiedFlightInfoPassengers})(Step2);

import React from 'react';
import {Typography, Grid2} from "@mui/material";
import PropTypes from 'prop-types';

const FormWasSubmittedMessage = ({title = '', subtitle= '', children= ''}) => {
    return (
        <Grid2 container>
            <Grid2 size={{xs: 12}}>
                <Typography variant={'body1'} color={'secondary'} style={{textAlign: 'left'}}>
                    {title}
                </Typography>
            </Grid2>
            <Grid2 size={{xs: 12}} >
                <Typography variant={'h6'} style={{textAlign: 'left'}}>
                    {subtitle}
                </Typography>
            </Grid2>
            <Grid2 size={{xs: 12}} >
                {children}
            </Grid2>
        </Grid2>
    );
};

FormWasSubmittedMessage.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    children: PropTypes.any,
};


export default FormWasSubmittedMessage;
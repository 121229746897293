import {EndpointBuilder} from "@reduxjs/toolkit/dist/query";

export const type = 'customerClaim';

export const customerClaimEndpoints = (builder:EndpointBuilder<any, any, any>) => ({
    getDuplicatedClaim: builder.query<any, any>({
        query: (params) => ({
            url: `/claims/duplicates`,
            method: 'POST',
            body: params
        }),
    }),

});

export default customerClaimEndpoints;

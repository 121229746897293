import APP_SETTINGS from "../../../../../../../config/settings";
// import moment from "moment";
import {t} from 'ttag';

export const TYPES = {
    ADT: t`Adult`,
    CHD: t`Child`,
    INF: t`Infant`
};

export const TYPES_MAP = type => {
    return TYPES[type.toUpperCase()] || type;
};

export const DATA = {
    passengerId: '',
    title: '',
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    type: '',
    email: '',
    contact: '',
    frequentFlyer: '',
};

export const DATA_VALIDATOR = {
    passengerId: s => /^.+$/g.test(s),
    title: s => /^.+$/g.test(s),
    firstName: s => /^.+$/g.test(s),
    lastName: s => /^.+$/g.test(s),
    // dateOfBirth: s => !s || s instanceof moment || /^\d{4}-\d{2}-\d{2}$/g.test(s),
    dateOfBirth: () => true,
    type: s => /^(inf|chd|adt|str|stu|yth|unn|cnn)$/gi.test(s),
    email: APP_SETTINGS.EMAIL_VALIDATION,
    //contact: s => !s || (s && /^[\s()+-]*([0-9][\s()+-]*){9,20}$/g.test(s)),
    contact: () => true,
    frequentFlyer: s => !s || (s && /^.*$/g.test(s)),
};

export const IS_PASSENGER_VALID = inputData => {

    if (!inputData.selected) {
        return true;
    }

    const passengerIdIsValid = DATA_VALIDATOR.passengerId(inputData.passengerId);
    const titleIsValid = DATA_VALIDATOR.title(inputData.title);
    const firstNameIsValid = DATA_VALIDATOR.firstName(inputData.firstName);
    const lastNameIsValid = DATA_VALIDATOR.lastName(inputData.lastName);
    const emailIsValid = DATA_VALIDATOR.email(inputData.email);
    const dateOfBirthIsValid = DATA_VALIDATOR.dateOfBirth(inputData.dateOfBirth);
    const typeIsValid = DATA_VALIDATOR.type(inputData.type);
    const frequentFlyerIsValid = DATA_VALIDATOR.frequentFlyer(inputData.frequentFlyer);
    const contactIsValid = DATA_VALIDATOR.contact(inputData.contact);
    return passengerIdIsValid
        && titleIsValid
        && firstNameIsValid
        && lastNameIsValid
        && emailIsValid
        && dateOfBirthIsValid
        && typeIsValid
        && frequentFlyerIsValid
        && contactIsValid;
};

import React from 'react';
import PropTypes from 'prop-types';
import {Container, Grid2} from "@mui/material";
import ProcessStateForm from "./process-state/process-state-form";
import {connect} from "react-redux";
import {customerProcessStateFormSubmit} from "../../../../services/customer-process-state/actions";

const Step1 = ({onSubmit = () => null, processNumber = ''}) => {
    return (
        <Container>
            <Grid2 container>
                <Grid2 size={{xs: 12}}>
                    <ProcessStateForm
                        onSubmit={({processNumber}) => onSubmit(processNumber)}
                        processNumber={processNumber} />
                </Grid2>
            </Grid2>
        </Container>
    );
};

Step1.propTypes = {
    onSubmit: PropTypes.func,
    processNumber: PropTypes.string,
};

export default connect(state => ({processNumber: state.customerProcessState.processNumber}), {onSubmit: customerProcessStateFormSubmit})(Step1);
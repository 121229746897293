export const DATA = {
    processNumber: '',
};

export const DATA_VALIDATOR = {
    processNumber: s => /CAS-\d+-\w{6}$/.test(s),
};

export const IS_PROCESS_VALID = inputData => {
    return DATA_VALIDATOR.processNumber(inputData.processNumber);
}
export const DATA = {
    claimType: null,
    claimSubtype: null,
};

export const DATA_VALIDATOR = {
    claimType: s => !!s,
    claimSubtype: s => !!s,
};

export const IS_FORM_VALID = inputData => {
    return DATA_VALIDATOR.claimType(inputData.claimType)
        && DATA_VALIDATOR.claimSubtype(inputData.claimSubtype);
};
import {t} from "ttag";
import React from 'react';
import PropTypes from 'prop-types';
import {TextField, Autocomplete, CircularProgress, ListItem} from "@mui/material";
import SUBJECT_TYPES from "../../../config/DATA/SUBJECT_TYPES.js";


const ClaimSubtypeAutocomplete = ({subjectTypes = SUBJECT_TYPES, placeHolder =  t`Start typing...`, claimType = null, label =  t`Claim Reason select`, value = null, onSelect = () => null, required = false, error = false, disabled = false, readOnly = false, onBlur = () => null, loading = false, fullWidth = false}) => {

    const subjectType = subjectTypes.find(l => l.subjectType === claimType);
    value = (subjectType && subjectType.subjectTypeDetails.find(l => l.reasonId === value)?.reason) || '';

    return (
        <Autocomplete
            onBlur={onBlur}
            disabled={disabled}
            readOnly={readOnly}
            autoHighlight
            options={subjectType ? subjectType.subjectTypeDetails : []}
            onChange={(e, o) => onSelect(o ? o.reasonId : '')}
            isOptionEqualToValue={(o, selectedOpt) => o.reasonId === selectedOpt.reasonId}
            getOptionLabel={o => (typeof o === 'object') ? o.reason : o}
            value={value}
            renderOption={(props, option) => (
                <ListItem {...props}>
                    {option.reason}
                </ListItem>
            )}
            renderInput={params => (
                <TextField
                    {...params}
                    variant="outlined"
                    error={error}
                    required={required}
                    autoFocus={false}
                    placeholder={placeHolder}
                    label={label}
                    fullWidth={fullWidth}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading ? <CircularProgress color='inherit' size={15} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                    ),
                    //readOnly: readOnly
                }}/>
            )}
        />
    );
};

ClaimSubtypeAutocomplete.propTypes = {
    subjectTypes: PropTypes.array,
    value: PropTypes.any,
    claimType: PropTypes.string,
    label: PropTypes.string,
    placeHolder: PropTypes.string,
    onSelect: PropTypes.func,
    onBlur: PropTypes.func,
    required: PropTypes.bool,
    error: PropTypes.bool,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    fullWidth: PropTypes.bool,
    loading: PropTypes.bool,
};

export default ClaimSubtypeAutocomplete;

import React from 'react';
import PropTypes from 'prop-types';
import {Button} from "@mui/material";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const ArrowButton = ({children = '', selected = false, icon = <KeyboardArrowRightIcon />, selectedIcon = <KeyboardArrowDownIcon />, ...props}) => {
    return (
        <Button
            color={selected ? 'primary' : 'secondary'}
            endIcon={selected ? selectedIcon : icon}
            {...props}>
            {children}
        </Button>
    );
};

ArrowButton.propTypes = {
    children: PropTypes.any,
    selected: PropTypes.bool,
    icon: PropTypes.any,
    selectedIcon: PropTypes.any,
};

export default ArrowButton;
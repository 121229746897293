import {t} from "ttag";
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import React, {useRef, useState} from 'react';
import {Checkbox, Grid2} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {Table, TableContainer,Button, TableHead, TableRow, TableCell, TableBody} from "@mui/material";
import withScreen from "../../../../../../../components/hoc/withScreen";
import FlightMobileRow from "../flight-form/flight-mobile-row";
import FlightTableRow from "../flight-form/flight-table-row";
import {FLIGHTS_VALIDATOR} from "./flight-list-data";
import styles from "./flights-list.module.scss";

const FlightList = ({onSubmit =  (flightsList) => console.log(flightsList), flights = [], screen, claimSubjects = []}) => {

    const containerRef = useRef();
    const [needAttention, setNeedAttention] = useState(false);
    const [localFlights, setLocalFlights] = useState([
        ...flights.map(f => ({selected: false, ...f}))
    ]);

    if (!flights || (Array.isArray(flights) && !flights.length)) {
        return null;
    }

    const onChange = (idx, flight) => {
        localFlights[idx] = flight;
        setLocalFlights([...localFlights])
    };

    const selectAll = (allSelected) => {
        setLocalFlights([...localFlights.map(f => ({...f, selected: allSelected}))]);
    };

    const localSubmitHandler = () => {
        if (FLIGHTS_VALIDATOR(localFlights)) {
            onSubmit([...localFlights]);
        } else {
            window.scrollTo(0, containerRef.current.offsetTop);
            setNeedAttention(true);
        }
    };

    const responsiveRender = () => {
        if (screen.upTo(screen.breakpoints.SM)) {
            return (
                <Grid2 spacing={2} container>
                    {localFlights.map((f, idx) => (
                        <Grid2 size={{xs: 12}} key={idx} >
                            <FlightMobileRow
                                key={idx}
                                claimSubjects={claimSubjects}
                                onFlightChange={(modifiedFlight) => onChange(idx, {...modifiedFlight})}
                                onSelect={(v) => onChange(idx, {...f, selected: v})}
                                needAttention={needAttention && f.selected}
                                selected={f.selected}
                                disabled={!f.selected}
                                flight={f}
                                readOnly={!f.selected}/>
                        </Grid2>
                    ))}
                </Grid2>
            );
        }


        return (
            <TableContainer className={styles.tableContainer}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell padding={"checkbox"}>
                                <Checkbox
                                    onChange={(e, value) => selectAll(!!value)}
                                    //checked={selected}
                                    value="secondary"
                                    color="secondary"
                                />
                            </TableCell>
                            <TableCell padding={'none'}>
                                {t`Flight`}
                            </TableCell>
                            <TableCell padding={'none'} align={'center'}>
                                {t`Origin`}
                            </TableCell>
                            <TableCell padding={'none'} align={'center'}>
                                {t`Destination`}
                            </TableCell>
                            <TableCell padding={'none'} align={'center'}>
                                {t`Claim`}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {localFlights.map((f, idx) => (
                            <FlightTableRow
                                key={idx}
                                claimSubjects={claimSubjects}
                                onFlightChange={(modifiedFlight) => onChange(idx, {...modifiedFlight})}
                                onSelect={(v) => onChange(idx, {...f, selected: v})}
                                needAttention={needAttention && f.selected}
                                selected={f.selected}
                                disabled={!f.selected}
                                flight={f}
                                readOnly={!f.selected}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }

    return (
        <Grid2 spacing={1} ref={containerRef} container>
            <Grid2 size={{xs: 12}}>
                {responsiveRender()}
            </Grid2>
            <Grid2 size={{xs: 12}} >
                <Button
                    className={styles.nextStepBtn}
                    disabled={!localFlights.some(f => !!f.selected)}
                    variant="contained"
                    color="secondary"
                    onClick={localSubmitHandler}
                    endIcon={<NavigateNextIcon/>}>
                    {/*{t`Add other information to your claim`}*/}
                    {t`Next`}
                </Button>
            </Grid2>
        </Grid2>
    );
};

FlightList.propTypes = {
    flights: PropTypes.array,
    onSubmit: PropTypes.func,
    claimSubjects: PropTypes.array,
};

export default connect(state => ({claimSubjects: state.app.subjectTypesList, flights: state.customerClaims.modifiedFlightInfo?.flights}))(withScreen(FlightList));
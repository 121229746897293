import React, {useState} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {useSnackbar} from 'notistack';
import {capitalize, styles} from "./notistack-with-content-resources";
import Collapse from '@mui/material/Collapse';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Box} from "@mui/material";

const VARIANTS = {
    DEFAULT: 'default',
    ERROR: 'error',
    SUCCESS: 'success',
    WARNING: 'warning',
    INFO: 'info'
};

/** HOW TO USE **/
/** https://iamhosseindhv.com/notistack/api#mutual
 * const { enqueueSnackbar, closeSnackbar } = useSnackbar();
 *
 * const key = enqueueSnackbar('title', {
 *    preventDuplicate: false,
 *    persist: false,
 *    autoHideDuration: 5000,
 *    onClose: reason => console.info('Notification was closed', reason),
 *    content: (key, message) => (
 *      <NotiStackWithContent id={key} message={message} variant={'info'}>
 *          some content
 *      </NotiStackWithContent>
 * )});
 *
 * //closeSnackbar(key);
 *
 *
 ** Redux use
 *
 * dispatch(addNotification('refreshing...', {variant: 'success', content: (key, message) => (
 *     <NotiStackWithContent id={key} message={message} variant={'info'}>
 *         some content
 *     </NotiStackWithContent>
 * )}))
 * **/
const NotiStackWithContent = React.forwardRef(({id, message = '', children = null, variant =  VARIANTS.DEFAULT}, ref) => {
    const {closeSnackbar} = useSnackbar();
    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const handleDismiss = () => {
        closeSnackbar(id);
    };

    const rootClasses = styles[`actionRoot${capitalize(variant)}`];

    return (
        <Card sx={styles.card} ref={ref}>
            <CardActions classes={{root: rootClasses}}>
                {/*<MuiAlert severity={variant}>{message}</MuiAlert>*/}
                <Typography variant="subtitle2" sx={styles.typography}>{message}</Typography>
                <Box sx={styles.icons}>
                    <IconButton
                        aria-label="Show more"
                        className={classnames(styles.expand, {[styles.expandOpen]: expanded})}
                        onClick={handleExpandClick}>
                        <ExpandMoreIcon/>
                    </IconButton>
                    <IconButton sx={styles.expand} onClick={handleDismiss}>
                        <CloseIcon/>
                    </IconButton>
                </Box>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <Paper sx={styles.collapse}>
                    {children}
                </Paper>
            </Collapse>
        </Card>
    );
});

NotiStackWithContent.propTypes = {
    id: PropTypes.number.isRequired,
    message: PropTypes.string,
    variant: PropTypes.oneOf(Object.values(VARIANTS)),
    children: PropTypes.any,
};

export {VARIANTS};

export default NotiStackWithContent;
import React, {useEffect, useState} from 'react';
import {Checkbox, TextField, Collapse, CardContent, CardHeader, Card, Grid2, FormControl, Typography} from "@mui/material";
import {DATA_VALIDATOR, TYPES_MAP} from './passenger-row-data';
import PropTypes from 'prop-types';
// import APP_SETTINGS from "../../../../../config/settings";
import PhoneNumberInput from "../../../../../../../components/phone-number-input/phone-number-input";
import {t} from 'ttag';
// import {KeyboardDatePicker} from "@material-ui/pickers";
import moment from "moment";

const PassengerMobileRow = ({onPaxChange = () => null, needAttention = false, onSelect = () => null, selected = false, passenger = null, readOnly = false, disabled = false, callOnPaxChangeWhenType = false}) => {

    const [data, setData] = useState({
        ...passenger
    });

    useEffect(() => {
        setData({...passenger});
    }, [passenger]);

    if (!passenger || !data) {
        return null;
    }

    const onChange = (fieldId, value, isDate = false) => {
        if (isDate) value.setHours(12);
        const modifiedData = {
            ...data,
            [fieldId]: isDate ? moment(value) : value
        };

        setData({...modifiedData});
        if (callOnPaxChangeWhenType || isDate) {
            onPaxChange({...modifiedData});
        }
    };

    return (
        <Card key={data.passengerId}>
            <CardHeader
                style={{cursor: 'pointer'}}
                onClick={() => onSelect(!selected)}
                avatar={(
                    <Checkbox
                        //onChange={(e, value) => onSelect(!!value)}
                        checked={selected}
                        value="secondary"
                        color="secondary"
                    />
                )}
                title={<><small>{data.title}</small> {data.firstName} {data.lastName}</>}
                subheader={TYPES_MAP(data.type)}>
            </CardHeader>
            <Collapse in={selected} timeout="auto">
                <CardContent>
                    <Grid2 spacing={2} container>
                        <Grid2 size={{xs:12, md:6}} >
                            <FormControl fullWidth>
                                <Typography style={{lineHeight: '1.415'}} variant={'caption'}>{t`Contact`}</Typography>
                                <PhoneNumberInput
                                    error={needAttention && !DATA_VALIDATOR.contact(data.contact)}
                                    onChange={(phoneNumber, country) => onChange('contact', phoneNumber)}
                                    onBlur={() => onPaxChange({...data})}
                                    value={data.contact ? data.contact : ''}
                                    defaultCountry={'pt'}
                                    disabled={disabled}
                                    readOnly={readOnly}
                                    required
                                />
                            </FormControl>
                        </Grid2>
{/*                        <Grid xs={12} sm={6} item>
                            <FormControl fullWidth>
                                <KeyboardDatePicker
                                    cancelLabel={t`Cancel`}
                                    okLabel={t`Ok`}
                                    disableFuture
                                    placeholder={'1980-01-20'}
                                    maxDateMessage={t`Date should be in the past.`}
                                    label={t`Date of birth`}
                                    error={needAttention && !DATA_VALIDATOR.dateOfBirth(data.dateOfBirth)}
                                    onBlur={() => onPaxChange({...data})}
                                    onChange={dateTime => onChange('dateOfBirth', dateTime, true)}
                                    value={data.dateOfBirth ? new Date(data.dateOfBirth.format(APP_SETTINGS.DATE_TIME_FORMAT)) : null}
                                    format="yyyy-MM-dd"
                                    ampm={false}
                                    disabled={disabled}
                                    readOnly={readOnly}
                                    inputProps={{
                                        readOnly: Boolean(readOnly),
                                        disabled: Boolean(readOnly),
                                    }}
                                    fullWidth/>
                            </FormControl>
                        </Grid>*/}
                        <Grid2 size={{xs:12, sm:6}}>
                            <TextField
                                disabled={disabled}
                                variant="outlined"
                                label={t`Email`}
                                style={{minWidth: '180px'}}
                                value={data.email ? data.email : ''}
                                error={needAttention && !DATA_VALIDATOR.email(data.email)}
                                //helperText={needAttention && !DATA_VALIDATOR.email(data.email) ? t`Please check field data` : ''}
                                onChange={(e) => onChange('email', e.target.value)}
                                onBlur={() => onPaxChange({...data})}
                                required
                                fullWidth
                                InputProps={{
                                    readOnly: readOnly
                                }}
                            />
                        </Grid2>
                        <Grid2 size={{xs:12, sm:6}} >
                            <TextField
                                disabled={disabled}
                                variant="outlined"
                                label={t`Frequent flyer`}
                                style={{minWidth: '180px'}}
                                value={data.frequentFlyer ? data.frequentFlyer : ''}
                                error={needAttention && !DATA_VALIDATOR.frequentFlyer(data.frequentFlyer)}
                                //helperText={needAttention && !DATA_VALIDATOR.frequentFlyer(data.frequentFlyer) ? t`Please check field data` : ''}
                                onChange={(e) => onChange('frequentFlyer', e.target.value)}
                                onBlur={() => onPaxChange({...data})}
                                fullWidth
                                InputProps={{
                                    readOnly: readOnly
                                }}
                            />
                        </Grid2>
                    </Grid2>
                </CardContent>
            </Collapse>
        </Card>
    );
}

PassengerMobileRow.propTypes = {
    passenger: PropTypes.object,
    needAttention: PropTypes.bool,
    selected: PropTypes.bool,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    onSelect: PropTypes.func,
    onPaxChange: PropTypes.func,
    callOnPaxChangeWhenType: PropTypes.bool,
};


export default PassengerMobileRow;
import moment from "moment";

export const DATA = {
    flightId: '',
    flightNumber: '',
    departure: '',
    origin: '',
    arrival: '',
    destination: '',
    claimCategory: '',
    claimSubCategory: '',
};

export const DATA_VALIDATOR = {
    flightId: s => /^.+$/g.test(s),
    flightNumber: s => /^.*$/g.test(s),
    departure: s => s instanceof moment || /^\d{4}-\d{2}-\d{2}$/g.test(s),
    origin: s => /^.+$/g.test(s),
    arrival: s => s instanceof moment || /^\d{4}-\d{2}-\d{2}$/g.test(s),
    destination: s => /^.+$/g.test(s),
    claimCategory: s => s && /^.+$/g.test(s),
    claimSubCategory: s => s && /^.+$/g.test(s),
};

export const IS_FLIGHT_VALID = inputData => {

    if (!inputData.selected) {
        return true;
    }

    return DATA_VALIDATOR.flightId(inputData.flightId)
        && DATA_VALIDATOR.flightNumber(inputData.flightNumber)
        && DATA_VALIDATOR.departure(inputData.departure)
        && DATA_VALIDATOR.origin(inputData.origin)
        && DATA_VALIDATOR.arrival(inputData.arrival)
        && DATA_VALIDATOR.destination(inputData.destination)
        && DATA_VALIDATOR.claimCategory(inputData.claimCategory)
        && DATA_VALIDATOR.claimSubCategory(inputData.claimSubCategory);
};
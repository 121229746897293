import React from 'react';
import PropTypes from 'prop-types';
import {Button, Step, StepContent, StepLabel, Stepper as StepperMui, Typography} from "@mui/material";
import {connect} from "react-redux";
import {setCurrentStep, setScene} from "../../../../../services/customer-claims/actions";
import APP_SETTINGS from "../../../../../config/settings";
import {t} from "ttag";
import Step2 from "../step-2/step-2";
import Step3 from "../step-3/step-3";
import Step4 from "../step-4/step-4";
import {SCENES} from "../../../../../services/customer-claims/mock";
import styles from '../../../common/stepper/stepper.module.scss';

const STEPS = {
    //STEP1: {label: t`Step 1`, navAllowed: APP_SETTINGS.DEBUG, content: <Step1/>},
    STEP2: {label: t`Passengers `, navAllowed: APP_SETTINGS.DEBUG, content: <Step2/>},
    STEP3: {label: t`Flights `, navAllowed: APP_SETTINGS.DEBUG, content: <Step3/>},
    STEP4: {label: t`Additional Details `, navAllowed: APP_SETTINGS.DEBUG, content: <Step4/>},
};
const Stepper = ({steps = Object.values(STEPS), step = 0, handleGoTo = () => null, handleBack = () => null, handleNext = () => null, handleReset = () => null, finishContent = <Typography>{t`Your claim was submitted.`}</Typography>, hideBackBtn = true, hideNextBtn = true, allStepsOpen = false}) => {

    const stepLabelExtraProps = {StepIconProps: {classes: {active: styles.stepIcon, completed: styles.stepIcon}}};
    if (allStepsOpen) stepLabelExtraProps.active = true;

    return (
        <div>
            <StepperMui activeStep={step} style={{padding: 0, backgroundColor: 'transparent'}} orientation="vertical">
                {Object.values(steps).map(({label, navAllowed, content}, idx) => (
                    <Step key={idx}>
                        {(navAllowed || idx <= step)
                            ? (
                                <StepLabel onClick={() => handleGoTo(idx)} style={{cursor: 'pointer'}} StepIconProps={{classes: {active: styles.stepIcon, completed: styles.stepIcon}}}>
                                    {label}
                                </StepLabel>
                            ) : (
                                <StepLabel StepIconProps={{classes: {active: styles.stepIcon, completed: styles.stepIcon}}}>
                                    {label}
                                </StepLabel>
                            )
                        }
                        <StepContent TransitionProps={allStepsOpen ? {in: true} : undefined}>
                            {content}
                            {!(hideBackBtn && hideNextBtn) && (
                                <div>
                                    <br/>
                                    {!hideBackBtn && (
                                        <Button
                                            disabled={step === 0}
                                            onClick={handleBack}>
                                            {t`Back`}
                                        </Button>
                                    )}
                                    {!hideNextBtn && (
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={handleNext}>
                                            {step === steps.length - 1 ? t`Finish` : t`Next`}
                                        </Button>
                                    )}
                                </div>
                            )}
                        </StepContent>
                    </Step>
                ))}
            </StepperMui>
            {step >= steps.length && (
                <>
                    {finishContent}
                    <Button onClick={handleReset}>
                        {t`Start over`}
                    </Button>
                </>
            )}
        </div>
    );
};

Stepper.propTypes = {
    steps: PropTypes.array,
    step: PropTypes.number,
    allStepsOpen: PropTypes.bool,
    finishContent: PropTypes.any,
    hideBackBtn: PropTypes.bool,
    hideNextBtn: PropTypes.bool,
    handleGoTo: PropTypes.func,
    handleBack: PropTypes.func,
    handleNext: PropTypes.func,
    handleReset: PropTypes.func,
};

export default connect(state => ({step: state.customerClaims.currentStep}), {
    handleGoTo: setCurrentStep,
    handleReset: () => setScene(SCENES.PNR_FORM)
})(Stepper);

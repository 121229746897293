import {t} from 'ttag';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import DateFnsUtils from "@date-io/date-fns";
import React, {useRef, useState} from 'react';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {LocalizationProvider as MuiPickersUtilsProvider} from "@mui/x-date-pickers";
import { Checkbox, Grid2, Button, TableRow, TableContainer, Table, TableBody, TableHead, TableCell } from "@mui/material";
import PassengerMobileRow from "../passenger-form/passenger-mobile-row";
import withScreen from "../../../../../../../components/hoc/withScreen";
import PassengerTableRow from "../passenger-form/passenger-table-row";
import APP_SETTINGS from "../../../../../../../config/settings";
import {PASSENGERS_VALIDATOR} from "./passenger-list-data";
import styles from './passengers-list.module.scss';

const PassengersList = ({onSubmit = (paxList) => console.log(paxList), passengers = [], screen = null}) => {

    const containerRef = useRef();
    const [needAttention, setNeedAttention] = useState(false);
    const [localPassengers, setLocalPassengers] = useState([
        ...passengers.map(p => ({selected: false, ...p}))
    ]);

    if (!passengers || (Array.isArray(passengers) && !passengers.length)) {
        return null;
    }

    const onChange = (idx, passenger) => {
        localPassengers[idx] = passenger;
        setLocalPassengers([...localPassengers]);
    };

    const localSubmitHandler = () => {
        if (PASSENGERS_VALIDATOR(localPassengers)) {
            onSubmit([...localPassengers]);
        } else {
            window.scrollTo(0, containerRef.current.offsetTop);
            setNeedAttention(true);
        }
    };

    const selectAll = (allSelected) => {
        setLocalPassengers([...localPassengers.map(p => ({...p, selected: allSelected}))]);
    };

    const responsiveRender = () => {
        if (screen.upTo(screen.breakpoints.SM)) {
            return (
                <Grid2 spacing={2} container>
                    {localPassengers.map((p, idx) => (
                        <Grid2 size={{xs: 12}} key={idx}>
                            <PassengerMobileRow
                                key={idx}
                                onPaxChange={(modifiedPassenger) => onChange(idx, {...modifiedPassenger})}
                                onSelect={(v) => onChange(idx, {...p, selected: v})}
                                needAttention={needAttention && p.selected}
                                selected={p.selected}
                                disabled={!p.selected}
                                passenger={p}/>
                        </Grid2>
                    ))}
                </Grid2>
            );
        }

        return (
            <TableContainer className={styles.tableContainer}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell padding={"checkbox"}>
                                <Checkbox
                                    onChange={(e, value) => selectAll(!!value)}
                                    //checked={selected}
                                    value="secondary"
                                    color="secondary"
                                />
                            </TableCell>
                            <TableCell padding={'none'}>
                                {t`Name`}
                            </TableCell>
                            <TableCell padding={'none'} align={'center'}>
                                {t`Phone`}
                            </TableCell>
                            {/*<TableCell padding={'none'} align={'center'}>
                                    {t`Date of Birth`}
                                </TableCell>*/}
                            <TableCell padding={'none'} align={'center'}>
                                {t`Email`}
                            </TableCell>
                            <TableCell padding={'none'} align={'center'}>
                                {t`Frequent Flyer Number`}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {localPassengers.map((p, idx) => (
                            <PassengerTableRow
                                key={idx}
                                onPaxChange={(modifiedPassenger) => onChange(idx, {...modifiedPassenger})}
                                onSelect={(v) => onChange(idx, {...p, selected: v})}
                                needAttention={needAttention && p.selected}
                                selected={p.selected}
                                disabled={!p.selected}
                                passenger={p}/>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };
    return (
        <Grid2 ref={containerRef} container>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={APP_SETTINGS.CURRENT_LOCALE}>
                <Grid2 size={{xs: 12}}>
                    {responsiveRender()}
                </Grid2>
                <Grid2 size={{xs: 12}}>
                    <Button
                        disabled={!localPassengers.some(p => !!p.selected)}
                        className={styles.nextStepBtn}
                        variant="contained"
                        color="secondary"
                        onClick={localSubmitHandler}
                        endIcon={<NavigateNextIcon/>}>
                        {/*{t`Select the flight(s) you have claim(s) for`}*/}
                        {t`Next`}
                    </Button>
                </Grid2>
            </MuiPickersUtilsProvider>
        </Grid2>
    );
};

PassengersList.propTypes = {
    passengers: PropTypes.array,
    onSubmit: PropTypes.func,
    screen: PropTypes.object,
};
export default connect(state => ({passengers: state.customerClaims.modifiedFlightInfo?.passengers}))(withScreen(PassengersList));

import React from 'react';
import FormWasSubmittedMessage from "../FormWasSubmittedMessage/FormWasSubmittedMessage";
import {t} from 'ttag';
import {Typography} from "@mui/material";

const ComplimentFormWasSubmitted = () => {
    return (
        <FormWasSubmittedMessage title={t`The form was successfully sent!`} className={'CustomerClaimsComplimentFormWasSubmittedMessage'} subtitle={t`Your contact is always important to us.`}>
            <Typography variant={'body2'} style={{textAlign: 'left'}}>
                {t`Your suggestion or compliment has been registered and will be analyzed, with all our attention.`}
            </Typography>
            <Typography variant={'body2'} style={{textAlign: 'left'}}>
                {t`Thank you for helping us to improve our service.`}
            </Typography>
        </FormWasSubmittedMessage>
    );
};

export default ComplimentFormWasSubmitted;
import {t} from "ttag";
import {connect} from "react-redux";
import {DataGrid} from "@mui/x-data-grid";
import React, {useEffect, useMemo, useState} from 'react';
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid2,
    Typography
} from "@mui/material";
import AdditionalInfoAndAttachmentsForm
    from "./additional-info-and-attachments-form/additional-info-and-attachments-form";
import {useGetDuplicatedClaimQuery} from "../../../../../services/api";
import APP_SETTINGS from "../../../../../config/settings";
import {customerProcessStateFormSubmit} from "../../../../../services/customer-process-state/actions";

const Step4 = ({customerClaims, onSubmit, claimSubjects}) => {

    const {data, isLoading: loading} = useGetDuplicatedClaimQuery({
        ...customerClaims.modifiedFlightInfo,
        lastName: customerClaims?.flightInfo?.lastName,
        additionalInfo: customerClaims.modifiedFlightInfo.additionalInformation,
        passengers: customerClaims?.modifiedFlightInfo?.passengers?.filter(p => !!p.selected).map(p => ({
            ...p,
            flyerID: p.frequentFlyer,
            birthDate: p.dateOfBirth?.format(APP_SETTINGS.DATE_FORMAT)
        })) || [],
        flights: customerClaims?.modifiedFlightInfo?.flights?.filter(f => !!f.selected).map(f => ({
            ...f,
            reasonId: f.claimSubCategory
        })) || [],
        language: APP_SETTINGS.LANGUAGES_MAP[APP_SETTINGS.CURRENT_LANGUAGE]
    });
    const [isPossibleCreateNewClaim, setIsPossibleCreateNewClaim] = useState(true);
    const [intentionToCreateNewClaim, setIntentionToCreateNewClaim] = useState(false);
    const [isValidateDelayIfDuplicateOpen, setIsValidateDelayIfDuplicateOpen] = useState(false);
    const [showJumpToProcessStateConfirmation, setShowGoToProcessStateConfirmation] = useState(false);
    const possibleDuplicatedClaim = useMemo(() => {

        return data?.length > 0;
    }, [data]);

    const filterByReasonId = (reasonId, returnSubject = false) => {
        for (const subject of claimSubjects) {
            const found = subject.subjectTypeDetails.find((detail) => detail.reasonId === parseInt(reasonId));
            if (found) {
                return returnSubject ? subject : found;
            }
        }
        return null;
    };

    useEffect(() => {
        if (data?.length > 0) {
            data?.forEach((claim) => {
                customerClaims?.modifiedFlightInfo?.flights?.filter(f => !!f.selected).map(f => {
                    if (parseInt(claim?.claimSubType?.id) === f.claimSubCategory) {
                        setIsPossibleCreateNewClaim(false);
                    }
                });
            });
        }
    }, [data]);
    const groupByName = (data) => {
        return data.reduce((acc, item) => {
            const name = `${item.firstName} ${item.lastName}`;
            if (!acc[name]) {
                acc[name] = [];
            }
            acc[name].push(item);
            return acc;
        }, {});
    };

    const groupedData = useMemo(() => groupByName(data || []), [data]);

    if (loading) {
        return <CircularProgress/>;
    }

    if (possibleDuplicatedClaim && !intentionToCreateNewClaim) {
        return (
            <Grid2 container spacing={3}>
                <Grid2 size={{xs: 12}}>
                    <Typography variant={'caption'} gutterBottom style={{textAlign: 'justify'}}>
                        {t`It seems that you have already submitted a claim with the same information. Select the claim you want to continue with. Or, if you want to submit a new claim, click on the button below.`}
                    </Typography>

                </Grid2>

                {Object.entries(groupedData).map(([name, rows]) => (
                    <Grid2 size={{xs: 12}} key={name}>
                        <Typography variant="h6">{name}</Typography>
                        <Box mb={1}>
                            <DataGrid
                                rows={rows}
                                columns={[
                                    {
                                        field: 'actions', headerName: '', width: 90, renderCell: (params) => (
                                            <Button variant="contained" color="primary" fullWidth onClick={() => {
                                                setShowGoToProcessStateConfirmation(params?.row?.process);
                                            }}>
                                                {t`View`}
                                            </Button>
                                        )
                                    },
                                    {field: 'flightNumber', headerName: t`Flight Number`, width: 75},
                                    {field: 'origin', headerName: t`Origin`, width: 45},
                                    {field: 'destination', headerName: t`Destination`, width: 45},
                                    {field: 'process', headerName: t`Process`, width: 160},
                                    {
                                        field: 'flightDate',
                                        headerName: t`Date`,
                                        width: 100,
                                        valueGetter: (value) => value?.split('T')[0]
                                    },
                                    {
                                        field: 'claimType',
                                        headerName: t`Type`,
                                        width: 200,
                                        valueGetter: (value, params) => filterByReasonId(params?.claimSubType?.id, true)?.subjectType
                                    },
                                    {
                                        field: 'claimSubType',
                                        headerName: t`Reason`,
                                        width: 200,
                                        valueGetter: (value) => filterByReasonId(value?.id)?.reason
                                    },
                                ]}
                                hideFooter={true}
                                disableColumnMenu={true}
                                disableColumnSelector={true}
                                disableColumnResize={true}
                                disableColumnSorting={true}
                                getRowId={(row) => row.claimId}
                                disableRowSelectionOnClick
                                onSelectionModelChange={(selection) => {
                                    setIsPossibleCreateNewClaim(selection.selectionModel.length === 0);
                                }}
                            />
                        </Box>
                    </Grid2>
                ))}

                {isPossibleCreateNewClaim && (
                    <Grid2 size={{xs: 12}}>
                        <Button variant="contained" color="primary" onClick={() => setIsValidateDelayIfDuplicateOpen(true)}>
                            {t`Submit new claim`}
                        </Button>
                    </Grid2>
                )}
                {isValidateDelayIfDuplicateOpen && (
                    <Dialog
                        open={isValidateDelayIfDuplicateOpen}
                        onClose={() => setIsValidateDelayIfDuplicateOpen(false)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{t`Submit claim - Possible Delay`}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {t`Please confirm that you want to submit the claim. Note that if the claim is a duplicate, it may take longer to process.`}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setIsValidateDelayIfDuplicateOpen(false)} color="primary">
                                {t`Return`}
                            </Button>
                            <Button onClick={() => {
                                setIsValidateDelayIfDuplicateOpen(false);
                                setIntentionToCreateNewClaim(true);
                            }} color="primary" autoFocus>
                                {t`Continue anyway`}
                            </Button>
                        </DialogActions>
                    </Dialog>)}
                {Boolean(showJumpToProcessStateConfirmation) && (
                    <Dialog
                        open={true}
                        onClose={() => setShowGoToProcessStateConfirmation(false)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{t`Jump to process state`}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {t`Do you want to jump to the process state of the selected claim?`}
                            </DialogContentText>
                            <DialogContentText id="alert-dialog-description">
                                {t`Your current process will be lost.`}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setShowGoToProcessStateConfirmation(false)} color="primary">
                                {t`No`}
                            </Button>
                            <Button onClick={() => {
                                onSubmit(showJumpToProcessStateConfirmation);
                            }} color="primary" autoFocus>
                                {t`Yes`}
                            </Button>
                        </DialogActions>
                    </Dialog>)}
            </Grid2>
        );
    }

    return (
        <Grid2 container spacing={1}>
            <Grid2 size={{xs: 12}}>
                <Typography variant={'caption'} gutterBottom style={{textAlign: 'justify'}}>
                    {t`All information is important and can help us improve. Use this field to provide more detailed information about your experience.`}
                </Typography>
            </Grid2>
            <Grid2 size={{xs: 12}}>
                <AdditionalInfoAndAttachmentsForm/>
            </Grid2>
        </Grid2>
    );
};

export default connect(state => ({
    customerClaims: state.customerClaims,
    claimSubjects: state.app.subjectTypesList
}), {onSubmit: customerProcessStateFormSubmit})(Step4);

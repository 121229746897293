import PropTypes from 'prop-types';
import {connect} from "react-redux";
import React, {useEffect} from 'react';
import {Container} from "@mui/material";
import NotistackProvider from "./components/notistack/notistack-provider/notistack-provider";
import RouteSwitcher from "./scenes/RouteSwitcher/RouteSwitcher";
import {retrieveClaimsLists} from "./services/app/actions";
import Loading from "./components/loading/loading";
import styles from './app.module.scss';

export const App = ({retrieveClaimsLists =  () => null}) => {

    useEffect(() => {
        retrieveClaimsLists();
    }, [retrieveClaimsLists])


    return (
        <Container maxWidth={'md'} className={styles.container}>
            <NotistackProvider>
                <Loading/>
                <RouteSwitcher/>
            </NotistackProvider>
        </Container>
    );
};

App.propTypes = {
    retrieveClaimsLists: PropTypes.func,
};

export default connect(null, {retrieveClaimsLists})(App);

import React, {useEffect, useState} from 'react';
import {Checkbox, Grid2, Typography, TableCell, TableRow} from "@mui/material";
import PropTypes from 'prop-types';
import {DATA_VALIDATOR} from "./flight-row-data";
import {t} from 'ttag'
import moment from "moment";
import APP_SETTINGS from "../../../../../../../config/settings";
import ClaimTypeAutocomplete
    from "../../../../../../../components/claims-autocomplete/claim-type-autocomplete/claim-type-autocomplete";
import ClaimSubtypeAutocomplete
    from "../../../../../../../components/claims-autocomplete/claim-subtype-autocomplete/claim-subtype-autocomplete";
const FlightTableRow = ({flight = null, claimSubjects = undefined, claimSubjectsLoading = false, onFlightChange = () => null, onSelect = () => null, needAttention = false, selected = false, disabled = false, readOnly = false, callOnFlightChangeWhenType = false}) => {

    const [data, setData] = useState({
        ...flight
    });

    useEffect(() => {
        setData({...flight});
    }, [flight]);

    if (!flight || !data) {
        return null;
    }

    const onChange = (fieldId, value) => {
        const modifiedData = {
            ...data,
            [fieldId]: value,
        };

        if (fieldId === 'claimCategory' && value !== data.claimCategory) {
            modifiedData.claimSubCategory = undefined;
        }

        setData({...modifiedData});
        if (callOnFlightChangeWhenType) {
            onFlightChange({...modifiedData})
        }
    };

    return (
        <TableRow key={data.flightId}>
            <TableCell padding="checkbox">
                <Checkbox
                    onChange={(e, value) => onSelect(!!value)}
                    checked={selected}
                    value="secondary"
                    color="secondary"
                />
            </TableCell>
            <TableCell component={'th'} padding={'none'} scope={'row'}>
                <div style={{width: '100%', minWidth: '80px', opacity: disabled ? '.6' : '1'}}>
                    <Typography variant={'caption'} color="textSecondary">
                        {data.flightNumber}
                    </Typography>
                    <Typography variant="subtitle2" gutterBottom>
                        {data.origin.iataCode} - {data.destination.iataCode}
                    </Typography>
                </div>
            </TableCell>
            <TableCell>
                <div style={{width: '100%', minWidth: '110px', opacity: disabled ? '.6' : '1'}}>
                    <Typography variant={'caption'} color="textSecondary">
                        {data.departure instanceof moment ? data.departure.format(APP_SETTINGS.DATE_TIME_FORMAT_NO_SEC) : ''}
                    </Typography>
                    <Typography variant="subtitle2" gutterBottom>
                        {data.origin.name}
                    </Typography>
                </div>
            </TableCell>
            <TableCell component={'th'} padding={'none'} scope={'row'}>
                <div style={{width: '100%', minWidth: '110px', opacity: disabled ? '.6' : '1'}}>
                    <Typography variant={'caption'} color="textSecondary">
                        {data.arrival instanceof moment ? data.arrival.format(APP_SETTINGS.DATE_TIME_FORMAT_NO_SEC) : ''}
                    </Typography>
                    <Typography variant="subtitle2" gutterBottom>
                        {data.destination.name}
                    </Typography>
                </div>
            </TableCell>
            <TableCell padding={'none'}>
                <Grid2 style={{width: '100%', minWidth: '350px'}} spacing={1} container>
                    <Grid2 size={{xs:6}}>
                        <ClaimTypeAutocomplete
                            subjectTypes={claimSubjects}
                            loading={claimSubjectsLoading}
                            label={t`Type`}
                            value={data.claimCategory}
                            onSelect={(claimCategory) => {
                                onChange('claimCategory', claimCategory);
                            }}
                            error={needAttention && !DATA_VALIDATOR.claimCategory(data.claimCategory)}
                            onBlur={() => onFlightChange({...data})}
                            disabled={disabled}
                            readOnly={readOnly}
                            fullWidth
                        />
                    </Grid2>
                    <Grid2 size={{xs:6}}>
                        <ClaimSubtypeAutocomplete
                            subjectTypes={claimSubjects}
                            loading={claimSubjectsLoading}
                            label={t`Reason`}
                            claimType={data.claimCategory}
                            value={data.claimSubCategory}
                            onSelect={(claimSubCategory) => onChange('claimSubCategory', claimSubCategory)}
                            error={needAttention && !DATA_VALIDATOR.claimSubCategory(data.claimSubCategory)}
                            onBlur={() => onFlightChange({...data})}
                            disabled={disabled}
                            readOnly={readOnly}
                            fullWidth
                        />
                    </Grid2>
                </Grid2>
            </TableCell>
        </TableRow>
    );
};

FlightTableRow.propTypes = {
    flight: PropTypes.object,
    onSelect: PropTypes.func,
    onFlightChange: PropTypes.func,
    selected: PropTypes.bool,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    needAttention: PropTypes.bool,
    claimSubjects: PropTypes.array,
    claimSubjectsLoading: PropTypes.bool,
    callOnPaxChangeWhenType: PropTypes.bool,
};

export default FlightTableRow;

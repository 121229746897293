import {t} from "ttag";
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import React, {useState} from 'react';
import {TextField, Grid2, Button} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {customerComplimentStep2Submit} from "../../../../../../services/customer-compliment/actions";
import AttachmentsField from "../../../../../../components/AttachmentsField/AttachmentsField";
import {DATA_VALIDATOR, IS_FORM_VALID} from "./additional-info-and-attachments-form-data";
import styles from './additional-info-and-attachments-form.module.scss';

const AdditionalInfoAndAttachmentsForm = ({onSubmit = () => null, additionalInformation = '', attachments = [], maxCharacters = 3000}) => {

    const [isSubmitted, setIsSubmitted] = useState(false);
    const [needAttention, setNeedAttention] = useState(false);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({
        additionalInformation,
        attachments
    });

    const onLocalChange = (fieldId, value) => {
        setData({
            ...data,
            [fieldId]: value
        })
    };

    const localSubmitHandler = () => {
        if (IS_FORM_VALID(data)) {
            onSubmit({
                ...data,
            });
        } else {
            setNeedAttention(true);
        }
    };

    return (
        <Grid2 container>
            <Grid2 size={{xs:12}}>
                <TextField
                    //label={t`Additional Information`}
                    variant="outlined"
                    value={data.additionalInformation ? data.additionalInformation : ''}
                    onChange={(e) => onLocalChange('additionalInformation', e.target.value.substring(0, maxCharacters))}
                    rows={4}
                    multiline
                    fullWidth
                    error={needAttention && !DATA_VALIDATOR.additionalInformation(data.additionalInformation)}
                    helperText={needAttention && !DATA_VALIDATOR.additionalInformation(data.additionalInformation)
                        ? t`Please check your field data`
                        : t`Characters left:` + ' ' + (maxCharacters - data.additionalInformation.length)}
                />
            </Grid2>
            <Grid2 size={{xs:12,md: 9}}>
                <AttachmentsField
                    loadingHandlerFn={setLoading}
                    attachments={data.attachments}
                    onChange={(attachments) => onLocalChange('attachments', attachments)}/>
            </Grid2>
            <Grid2 size={{xs:12}} align={'right'}>
                <Button
                    disabled={loading || isSubmitted}
                    className={styles.submitBtn}
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                            localSubmitHandler()
                            setIsSubmitted(true)
                        }}
                    endIcon={<NavigateNextIcon/>}>
                    {t`Submit`}
                </Button>
            </Grid2>
        </Grid2>
    )
};

AdditionalInfoAndAttachmentsForm.propTypes = {
    additionalInformation: PropTypes.string,
    attachments: PropTypes.array,
    onSubmit: PropTypes.func,
    maxCharacters: PropTypes.number,
};

export default connect(state => ({
    additionalInformation: state.customerCompliment.complimentInfo?.additionalInformation,
    attachments: state.customerCompliment.complimentInfo?.attachments
}), {
    onSubmit: ({additionalInformation, attachments}) => customerComplimentStep2Submit(additionalInformation, attachments)
})(AdditionalInfoAndAttachmentsForm);

import * as TYPES from "./types";
import DEFAULT_STATE from "./default-state";

export default function (state = DEFAULT_STATE, action) {
    switch (action.type) {
        case TYPES.SET_COMPLAINT_SCENE:
            return {...state, complaintScene: action.complaintScene};
        case TYPES.SET_COMPLAINT_CURRENT_STEP:
            return {...state, complaintCurrentStep: action.complaintCurrentStep};
        case TYPES.SET_COMPLAINT_INFO:
            return {...state, complaintInfo: action.complaintInfo};
        case TYPES.SET_COMPLAINT_INFO_CUSTOMER:
            return {...state, complaintInfo: {...state.complaintInfo, customer: action.customer}};
        case TYPES.SET_COMPLAINT_INFO_CLASSIFICATION:
            return {...state, complaintInfo: {...state.complaintInfo, classification: action.classification}};
        case TYPES.SET_COMPLAINT_INFO_ADDITIONAL_INFORMATION:
            return {...state, complaintInfo: {...state.complaintInfo, additionalInformation: action.additionalInformation}};
        case TYPES.SET_COMPLAINT_INFO_ATTACHMENTS:
            return {...state, complaintInfo: {...state.complaintInfo, attachments: action.attachments}};
        default:
            return state;
    }
}

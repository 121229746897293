import {t} from 'ttag';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {Container, TextField, Button, Grid2} from "@mui/material";
import {DATA, DATA_FILLED, DATA_VALIDATOR, isAllValid} from "./pnr-form-data";
import APP_SETTINGS from "../../../../../../config/settings";
import styles from './pnr-form.module.scss';

const PnrForm = ({onSubmit = () => null, isAGroup = false, ticketNumberError = false}) => {

    const RESERVATION_CODE_MAX_CHARS = 13;

    const [needAttention, setNeedAttention] = useState(false);
    const [localTicketNumberError, setLocalTicketNumberError] = useState(ticketNumberError);
    const [data, setData] = useState(APP_SETTINGS.DEBUG ? {...DATA_FILLED} : {...DATA});

    const onChange = (fieldId, value) => {
        if ((fieldId === 'pnr' || fieldId === 'ticketNumber') && value.length > RESERVATION_CODE_MAX_CHARS) {
            value = value.substring(0, RESERVATION_CODE_MAX_CHARS)
        }

        setData({
            ...data,
            [fieldId]: value
        });
    };

    useEffect(() => {
        setLocalTicketNumberError(ticketNumberError);
    }, [setLocalTicketNumberError, ticketNumberError]);

    const localSubmitHandler = () => {
        if (isAllValid(data, isAGroup)) {
            onSubmit({...data});
        } else {
            setNeedAttention(true);
        }
    };

    const onKeyPressed = (e) => {
        if (e.key === 'Enter') {
            localSubmitHandler();
        }
    };

    return (
        <Container>
            <Grid2 spacing={2} container>
                <Grid2 size={{xs:12, sm: 6, md:6}}>
                    <TextField
                        label={t`Booking reference`}
                        variant="outlined"
                        value={data.pnr}
                        disabled={isAGroup}
                        error={needAttention && !DATA_VALIDATOR.pnr(data.pnr)}
                        helperText={needAttention && !DATA_VALIDATOR.pnr(data.pnr) ? t`Please check field data` : t`Ex. X2C1V3 or 3310123456789`}
                        onChange={e => onChange('pnr', e.target.value)}
                        required
                        fullWidth
                    />
                </Grid2>
                <Grid2 size={{xs:12, sm: 6, md:6}}>
                    <TextField
                        label={t`Last name`}
                        variant="outlined"
                        value={data.lastName}
                        disabled={isAGroup}
                        error={needAttention && !DATA_VALIDATOR.lastName(data.lastName)}
                        helperText={needAttention && !DATA_VALIDATOR.lastName(data.lastName) ? t`Please check field data` : ''}
                        onChange={e => onChange('lastName', e.target.value)}
                        onKeyPress={onKeyPressed}
                        required
                        fullWidth
                    />
                </Grid2>
                {isAGroup && (
                    <Grid2 size={{xs:12, sm: 6, md:6}}>
                        <TextField
                            label={t`Ticket number`}
                            variant="outlined"
                            value={data.ticketNumber}
                            error={localTicketNumberError || (needAttention && !DATA_VALIDATOR.ticketNumber(data.ticketNumber))}
                            helperText={needAttention && !DATA_VALIDATOR.ticketNumber(data.ticketNumber) ? t`Please check field data` : t`This is a group reservation, please insert your ticket number. (Ex. 3310123456789)`}
                            onChange={e => onChange('ticketNumber', e.target.value)}
                            onKeyPress={onKeyPressed}
                            required
                            autoFocus
                            fullWidth
                        />
                    </Grid2>
                )}
                <Grid2 size={{xs:12}}>
                    <Button
                        className={styles.submitBtn}
                        variant="contained"
                        color="secondary"
                        onClick={localSubmitHandler}
                        endIcon={<NavigateNextIcon/>}>
                        {/*{t`Select passenger(s)`}*/}
                        {t`Next`}
                    </Button>
                </Grid2>
            </Grid2>
        </Container>
    );
};

PnrForm.propTypes = {
    onSubmit: PropTypes.func,
    isAGroup: PropTypes.bool,
    ticketNumberError: PropTypes.bool,
};

export default PnrForm;
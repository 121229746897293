export const SCENES = {
    STEPPER: 'STEPPER'
}

export const COMPLIMENT_INFO = {
    customer: {
        title: '',
        name: '',
        email: '',
        contact: '',
        frequentFlyer: ''
    },
    classification: null,
    additionalInformation: '',
    attachments: [],
};

export const COMPLIMENT_INFO_FILLED = {
    customer: {
        title: 'mr',
        name: 'Joshua Alves',
        email: 'joshua.alves@sata.pt',
        contact: '',
        frequentFlyer: '123456',
    },
    classification: null,
    additionalInformation: '',
    attachments: [],
};

import React, {createRef} from 'react';
import PropTypes from 'prop-types';
import {IconButton} from "@mui/material";
import {SnackbarProvider} from "notistack";
import CloseIcon from '@mui/icons-material/Close';
import NotistackNotifier from "../notistack-notifier/notistack-notifier";

const NotistackProvider = ({children = null, ...otherProps}) => {


    // add action to all snackbars
    const notiStackRef = createRef();
    const onClickDismiss = key => {
        notiStackRef.current.closeSnackbar(key);
    };

    return (
        <SnackbarProvider
            ref={notiStackRef}
            maxSnack={5}
            action={key => (
                <IconButton onClick={() => onClickDismiss(key)}>
                    <CloseIcon/>
                </IconButton>
            )}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            dense
            {...otherProps}>
            <NotistackNotifier/>
            {children}
        </SnackbarProvider>
    );
};

NotistackProvider.propTypes = {
    children: PropTypes.any.isRequired,
};


export default NotistackProvider;

import {t} from "ttag";
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {Grid2, Typography} from "@mui/material";
import {customerComplaintStep2Submit} from "../../../../../services/customer-complaint/actions";
import ClaimsForm from "./claims-form/claims-form";

const Step2 = ({onSubmit = () => null}) => {

    return (
        <Grid2 container spacing={1}>
            <Grid2 size={{xs: 12}}>
                <Typography variant={'caption'} gutterBottom style={{textAlign: 'justify'}}>
                    {t`Select the reason for your complaint.`}
                </Typography>
            </Grid2>
            <Grid2 size={{xs: 12}}>
                <ClaimsForm onSubmit={onSubmit}/>
            </Grid2>
        </Grid2>
    );
};

Step2.propTypes = {
    onSubmit: PropTypes.func,
};


export default connect(null, {onSubmit: customerComplaintStep2Submit})(Step2);

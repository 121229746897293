import enLocale from "date-fns/locale/en-US";
import ptLocale from "date-fns/locale/pt-BR";
import frLocale from "date-fns/locale/fr";

export const LANG_KEY = 'LANGUAGE';

export const LANGUAGES = {
    EN: 'en',
    PT: 'pt',
    FR: 'fr'
};

export const LANGUAGES_MAP = {
    en: 'en_US',
    pt: 'pt_PT',
    fr: 'fr_FR'
};

export const LOCALE_MAP = {
    en: enLocale,
    pt: ptLocale,
    fr: frLocale
};

export const DEFAULT_LANGUAGE = LANGUAGES.EN;
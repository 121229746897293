import {t} from 'ttag';
import React from 'react';
import {Typography} from "@mui/material";
import FormWasSubmittedMessage from "../FormWasSubmittedMessage/FormWasSubmittedMessage";

const ProcessStateFormWasSubmitted = () => {
    return (
        <FormWasSubmittedMessage title={t`The form was successfully sent!`} subtitle={t`Your contact is always important to us.`}>
            <Typography variant={'body2'} style={{textAlign: 'left'}}>
                {t`You will receive an email with the status of your complaint in a few moments.`}
            </Typography>
        </FormWasSubmittedMessage>
    );
};

export default ProcessStateFormWasSubmitted;
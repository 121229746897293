import APP_SETTINGS from "../../config/settings";

export const APP_SCENES = {
    COMPLAINT_WITH_RESERVATION: 'COMPLAINT_WITH_RESERVATION',
    COMPLAINT_WITHOUT_RESERVATION: 'COMPLAINT_WITHOUT_RESERVATION',
    COMPLAINT_SELECTOR: 'COMPLAINT_SELECTOR',
    COMPLIMENT: 'COMPLIMENT',
    CLAIMS_FORM_MESSAGE_WAS_SUBMITTED: 'CLAIMS_FORM_MESSAGE_WAS_SUBMITTED',
    COMPLIMENT_FORM_MESSAGE_WAS_SUBMITTED: 'COMPLIMENT_FORM_MESSAGE_WAS_SUBMITTED',
    PROCESS_STATE: 'PROCESS_STATE',
    PROCESS_STATE_MESSAGE_WAS_SUBMITTED: 'PROCESS_STATE_MESSAGE_WAS_SUBMITTED',
    PROCESS_STATE_DETAILS: 'PROCESS_STATE_DETAILS',
}

const DEFAULT_STATE = {
    loading: false,
    language: APP_SETTINGS.CURRENT_LANGUAGE,
    appScene: APP_SCENES.COMPLAINT_WITH_RESERVATION,
    processNumberDetails: null,
    notifications: [],
    subjectTypesList: [],
    subjectTypesListFiltered: []
};

export default DEFAULT_STATE;
import {t} from 'ttag';
import PropTypes from 'prop-types';
import React, {useState, createRef, useEffect} from 'react';
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {Button, TextField, Container, Grid2} from "@mui/material";
import {DATA, DATA_VALIDATOR, IS_PROCESS_VALID} from "./process-state-form-data";
import styles from './process-state-form.module.scss'

const ProcessStateForm = ({onSubmit = () => null, processNumber = '', processNumberMaxChars = Number.MAX_SAFE_INTEGER}) => {

    const fieldRef = createRef();
    const [needAttention, setNeedAttention] = useState(false);
    const [data, setData] = useState({
        ...DATA
    });

    useEffect(() => {
        setData({
            processNumber: processNumber
        });
    }, [processNumber]);

    const onChange = (fieldId, value) => {
        if (fieldId === 'processNumber' && value.length > processNumberMaxChars) {
            value = value.substring(0, processNumberMaxChars);
        }

        setData({
            ...data,
            [fieldId]: value
        });
    };

    const localHandlerSubmit = () => {
        if (IS_PROCESS_VALID(data)) {
            onSubmit({...data});
        } else {
            setNeedAttention(true);
            fieldRef.current.focus();
        }
    }

    const onKeyPressed = (e) => {
        if (e.key === 'Enter') {
            localHandlerSubmit();
        }
    };

    return (
       <Container>
           <Grid2 spacing={2} container>
               <Grid2 size={{xs: 12}} >
                   <TextField
                       label={t`Process Number`}
                       value={data.processNumber}
                       error={needAttention && !DATA_VALIDATOR.processNumber(data.processNumber)}
                       helperText={needAttention && !DATA_VALIDATOR.processNumber(data.processNumber) ? t`Please check your field data` : ''}
                       onChange={e => onChange('processNumber', e.target.value)}
                       onKeyPress={onKeyPressed}
                       inputRef={fieldRef}
                       variant="standard"
                       required
                       fullWidth
                   />
               </Grid2>
               <Grid2 size={{xs: 12}} >
                   <Button
                       className={styles.submitBtn}
                       variant="contained"
                       color="secondary"
                       onClick={localHandlerSubmit}
                       endIcon={<NavigateNextIcon/>}>
                       {t`Submit`}
                   </Button>
               </Grid2>
           </Grid2>
       </Container>
    );
};

ProcessStateForm.propTypes = {
    onSubmit: PropTypes.func,
    processNumber: PropTypes.string,
    processNumberMaxChars: PropTypes.number,
};

export default ProcessStateForm;
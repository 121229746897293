import {t} from 'ttag';
import React from 'react';
import {connect} from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import FormWasSubmittedMessage from "../FormWasSubmittedMessage/FormWasSubmittedMessage";
import {Typography} from "@mui/material";

const ProcessStateDetails = ({processNumberDetails}) => {

    if (!processNumberDetails) {
        return <CircularProgress/>;
    }
    const statusHeader = (value) => {
        switch (value.toLowerCase()) {
            case "internal reply received":
            case "new":
            case "missing information":
            case "in analysis":
            case "evaluation and decision":
            case "waiting for information":
                return t`Recebido / Em análise`;

            case "customer response received":
                return t`Resposta de cliente recebida - em reanálise`;

            case "filed without reply sent":
            case "filed (without reply sent)":
                return t`Processo concluído`;

            case "replied":
            case "reply sent":
                return t`Resposta enviada a cliente`;

            case "merged":
            case "cancelled":
                return t`O seu processo tem outra referência`;

            case "migrated":
                return t`Contate os nossos serviços`;

            case "waiting for documentation":
                return t`Submissão pendente – deve concluir o seu processo`;

            case "voucher assigned":
                return t`Voucher atribuído / processo concluído`;

            case "transfer to other services":
            case "forwarded":
                return t`Processo reencaminhado para outros serviços`;

            case 'dms error':
                return t`Processo de pagamento em validação`;

            case "payment of compensation":
                return t`Pagamento de compensação`;

            case "payment of expense":
                return t`Pagamento de despesa`;

            case "claim":
            default:
                return t`Your process is in an unknown state`;
        }
    }

    const paymentStatusHeader = (value) => {
        switch (value.toLowerCase()) {
            case 'sent for customer acceptance':
                return t`Sent for customer acceptance`;
            case 'payment process awaiting validation':
                return t`Payment process awaiting validation`;
            case 'reanalyzing':
                return t`Reanalyzing`;
            case 'transfer being processed':
                return t`Transfer being processed`;
            case 'transfer processed / process concluded':
                return t`Transfer processed / Process concluded`;
            default:
                return t`Processo de pagamento em validação`;
        }
    }

    return (
        <FormWasSubmittedMessage title={processNumberDetails?.processNumber}
                                 subtitle={statusHeader(processNumberDetails.response['Status Reason'])}
        >
            {processNumberDetails?.response['PaymentStatus'] && (
                <Typography
                    variant={'body1'} my={2}>{t`Payment status:`} {paymentStatusHeader(processNumberDetails?.response['PaymentStatus']?.name)}</Typography>
            )}
        </FormWasSubmittedMessage>
    );
};

export default connect(state => ({processNumberDetails: state.app.processNumberDetails}))(ProcessStateDetails);
import * as TYPES from "./types";
import DEFAULT_STATE from "./default-state";

export default function (state = DEFAULT_STATE, action) {
    switch (action.type) {
        case TYPES.SET_COMPLIMENT_SCENE:
            return {...state, complimentScene: action.complimentScene};
        case TYPES.SET_COMPLIMENT_CURRENT_STEP:
            return {...state, complimentCurrentStep: action.complimentCurrentStep};
        case TYPES.SET_COMPLIMENT_INFO:
            return {...state, complimentInfo: action.complimentInfo};
        case TYPES.SET_COMPLIMENT_INFO_CUSTOMER:
            return {...state, complimentInfo: {...state.complimentInfo, customer: action.customer}};
        case TYPES.SET_COMPLIMENT_INFO_CLASSIFICATION:
            return {...state, complimentInfo: {...state.complimentInfo, classification: action.classification}};
        case TYPES.SET_COMPLIMENT_INFO_ADDITIONAL_INFORMATION:
            return {...state, complimentInfo: {...state.complimentInfo, additionalInformation: action.additionalInformation}};
        case TYPES.SET_COMPLIMENT_INFO_ATTACHMENTS:
            return {...state, complimentInfo: {...state.complimentInfo, attachments: action.attachments}};
        default:
            return state;
    }
}
